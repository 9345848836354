<ng-template #snackbarTemplate>
  <div class="snackbar-wrapper">
    <mat-spinner
      *ngIf="currentState?.status === 'IN_PROGRESS'"
      diameter="20"
      color="accent"
    ></mat-spinner>
    <ng-container *ngIf="currentState as state">
      <div class="in-progress" *ngIf="state.status === 'IN_PROGRESS'">
        <div *ngIf="(state.totalAliases || 0) > 0">
          {{ 'googleSync.inProgressAliases' | translate: { totalAliases: totalAliases } }}
        </div>
        <div *ngIf="(state.totalEmployees || 0) > 0">
          {{ 'googleSync.inProgressEmployees' | translate: { totalEmployees: totalEmployees } }}
        </div>
        <div *ngIf="errorCount > 0">
          {{ 'googleSync.inProgressErrors' | translate: { errorCount: errorCount } }}
        </div>
      </div>

      <div class="finished" *ngIf="state.status === 'COMPLETED_SUCCESSFULLY'">
        <mat-icon>check</mat-icon>
        <span class="finished-label">
          {{ 'googleSync.finished' | translate }}<br />
          {{ 'googleSync.syncedEmployees' | translate: { syncedEmployees: syncedEmployeesCount } }}
          &nbsp;&&nbsp;
          {{ 'googleSync.syncedAliases' | translate: { syncedAliases: syncedAliasesCount } }}
        </span>
        <button class="cancel" mat-stroked-button (click)="close()">
          {{ 'close' | translate }}
        </button>
      </div>

      <div class="finished-error" *ngIf="state.status === 'COMPLETED_WITH_ERROR'">
        <mat-icon>warning</mat-icon>
        <span class="finished-label">
          {{ 'googleSync.error' | translate }}
        </span>
        <button class="cancel" mat-stroked-button (click)="showErrors()">
          {{ 'googleSync.showErrors' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="!loading; else loadingTemplate">
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>{{ 'client.name' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="name-wrapper" (click)="goToClient(element.domain)"
                [matTooltip]="'client.goToClient' | translate ">
        <mat-icon>open_in_browser</mat-icon>
        <img [src]="element.photoUrl || '/assets/img/favicon.png'" class="client-image">
        <div style="display: flex; flex-direction: column; gap: 4px">
          <span>{{ element.name || '-' }}</span>
          <code>{{ element.domain }}</code>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="googleAdminEmail">
      <mat-header-cell *matHeaderCellDef>{{ 'client.googleAdminEmail' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <code>{{ element.googleAdminEmail }}</code>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef>{{ 'client.createdAt' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" [title]="element.createdAt">
        <span [matTooltip]="element.createdAt">{{ element.createdAt | localizedDate }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="stats">
      <mat-header-cell *matHeaderCellDef>{{ 'client.stats' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="chip">
          {{ element.stats.current.numberOfEmployeesWithSignatureInstalled }} / {{ element.stats.current.numberOfEmployees }}
        </span>
        <span class="previous-stats">
          ({{ element.stats.previous.numberOfEmployeesWithSignatureInstalled }} / {{ element.stats.previous.numberOfEmployees }})
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="planId">
      <mat-header-cell *matHeaderCellDef>{{ 'client.subscription' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" [title]="element.planId">
        <span [matTooltip]="element.planId">{{ element.planId }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="trialFinishDate">
      <mat-header-cell *matHeaderCellDef>{{ 'client.trialFinishDate' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" [title]="element.trialFinishDate">
        <span [matTooltip]="element.trialFinishDate">{{ element.trialFinishDate | localizedDate }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
      <mat-header-cell *matHeaderCellDef>{{ 'client.language' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-select
          class="language-dropdown"
          [(value)]="element.language"
          (selectionChange)="updateLanguage(element.domain, $event.value)"
        >
          <mat-option value="en">EN</mat-option>
          <mat-option value="es">ES</mat-option>
          <mat-option value="pl">PL</mat-option>
        </mat-select>
      </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="actions" *ngIf="actionsTemplate">
      <mat-header-cell *matHeaderCellDef>
        {{ 'client.actions' | translate }}
        <mat-icon title="{{ 'client.downloadReport' | translate }}" (click)="generateReport()" class="report-icon">cloud_download</mat-icon>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-content *ngTemplateOutlet="actionsTemplate; context:{client: element}"></ng-content>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>
</ng-container>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingSettingsService } from '../../billing/settings/billing-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-expired-trial-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class ExpiredTrialSuccessComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private billingSettingsService: BillingSettingsService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.verifySession();
  }

  verifySession(): void {
    const sessionId = this.route.snapshot.queryParamMap.get('session_id');

    if (sessionId) {
      this.billingSettingsService.verifyCheckoutSession(sessionId).subscribe({
        next: () => {
          this.translate.get('trial.expired.success.paymentSuccessAlert').subscribe((translation: string) => {
            this.toastr.success(translation);
          });
        },
        error: () => {
          this.translate.get('trial.expired.success.paymentErrorAlert').subscribe((translation: string) => {
            this.toastr.error(translation);
          });
        }
      });
    } else {
      this.translate.get('trial.expired.success.missingSessionId').subscribe((translation: string) => {
        this.toastr.warning(translation);
      });
    }
  }

  backToApp(): void {
    this.router.navigate(['/']);
  }
}

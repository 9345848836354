import { SignatureInstallationState } from './signature-installation-state.interface';

export const POLL_STATE_UNTIL_INSTALLATION_FINISHED = 'POLL_STATE_UNTIL_INSTALLATION_FINISHED';
export const START_CHECKING_FOR_INSTALLATION_IN_PROGRESS = 'START_CHECKING_FOR_INSTALLATION_IN_PROGRESS';
export const ADD_INSTALLATION_STATE_PROGRESS = 'ADD_INSTALLATION_STATE_PROGRESS';
export const FETCH_SIGNATURE_INSTALLATION_STATE_FAILURE = 'FETCH_SIGNATURE_INSTALLATION_STATE_FAILURE';
export const RESET_INSTALLATION_STATE = 'RESET_INSTALLATION_STATE';

export class PollStateUntilInstallationFinishedAction {
  readonly type = POLL_STATE_UNTIL_INSTALLATION_FINISHED;

  constructor() {
  }
}

export class AddInstallationStateProgressAction {
  readonly type = ADD_INSTALLATION_STATE_PROGRESS;

  constructor(public payload: SignatureInstallationState) {
  }
}

export class StartCheckingForInstallationInProgressAction {
  readonly type = START_CHECKING_FOR_INSTALLATION_IN_PROGRESS;

  constructor() {
  }
}

export class FetchSignatureInstallationStateFailureAction {
  readonly type = FETCH_SIGNATURE_INSTALLATION_STATE_FAILURE;

  constructor(public payload: any) {
  }
}

export class ResetInstallationStateAction {
  readonly type = RESET_INSTALLATION_STATE;

  constructor() {
  }
}

export type SignatureInstallationStateActions =
  | PollStateUntilInstallationFinishedAction
  | AddInstallationStateProgressAction
  | StartCheckingForInstallationInProgressAction
  | FetchSignatureInstallationStateFailureAction
  | ResetInstallationStateAction;

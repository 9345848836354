import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GoogleSyncState } from './google-sync-state.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleSyncStateService {
  constructor(private http: HttpClient) {}

  public getInProgressState(): Observable<GoogleSyncState[]> {
    return this.http.get<GoogleSyncState[]>('/google-sync-state');
  }
}

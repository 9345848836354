import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleSyncState } from '../google-sync-state.interface';

@Component({
  selector: 'app-google-sync-error-dialog',
  templateUrl: './google-sync-error-dialog.component.html',
  styleUrls: ['./google-sync-error-dialog.component.scss'],
})
export class GoogleSyncErrorDialogComponent {
  public errors = [];

  constructor(@Inject(MAT_DIALOG_DATA) public state: GoogleSyncState) {
    this.errors = state.syncs.filter((r) => r.error);
  }
}

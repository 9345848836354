import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ClientTableRow } from "./client-table.interace";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { StoreExtendedService } from "../store-extended.service";
import {
  CHANGE_ACCOUNT_SUCCESS,
  ChangeAccountAction,
} from "../../accounts/accounts.actions";
import { ClientWithStats } from "../../client/client.interface";
import { TranslateService } from "@ngx-translate/core";
import { ReportsService } from "../reports/reports.service";
import { ClientService } from "../../client/client.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-client-table",
  templateUrl: "./client-table.component.html",
  styleUrls: ["./client-table.component.scss"],
})
export class ClientTableComponent implements OnInit {
  public displayedColumns = [
    "name",
    "googleAdminEmail",
    "createdAt",
    "stats",
    "planId",
    "trialFinishDate",
    "language",
    "actions",
  ];
  public clients: ClientWithStats[];

  @Input()
  public data: Observable<ClientTableRow[]>;

  @Input()
  public actionsTemplate?: TemplateRef<any>;

  public dataSource: MatTableDataSource<ClientTableRow>;
  @ViewChild(MatSort) sort: MatSort;

  public loading: boolean = true;

  constructor(
    private readonly store: StoreExtendedService,
    private readonly router: Router,
    private translate: TranslateService,
    private reportsService: ReportsService,
    private clientService: ClientService,
    private toastrService: ToastrService
  ) {}

  async ngOnInit() {
    this.dataSource = new MatTableDataSource([]);
    this.loadData();
    if (!this.actionsTemplate) {
      this.displayedColumns.pop();
    }
  }

  loadData = () => {
    this.loading = true;
    this.data.subscribe((clients) => {
      this.clients = [...clients];
      this.dataSource.data = this.clients;
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  };

  updateLanguage(domain: string, language: string) {
    this.clientService.updateLanguage(domain, language).subscribe(() => {
      this.toastrService.success(
        this.translate.instant("client.languageUpdateSuccess")
      );
      this.loadData();
    });
  }

  goToClient(domain: string) {
    this.store.waitForAction(CHANGE_ACCOUNT_SUCCESS).subscribe(() => {
      this.router.navigate(["signatures"]);
    });
    this.store.dispatch(new ChangeAccountAction(domain));
  }

  generateReport = () =>
    this.reportsService.generateClientsReport(this.clients);
}

import { Action } from '@ngrx/store';
import { GoogleSyncState } from './google-sync-state.interface';

export const START_CHECKING_FOR_GOOGLE_SYNC_IN_PROGRESS = '[GoogleSync] Start Checking For Google Sync In Progress';
export const POLL_GOOGLE_SYNC_STATE_UNTIL_FINISHED = '[GoogleSync] Poll Google Sync State Until Finished';
export const ADD_GOOGLE_SYNC_STATE_PROGRESS = '[GoogleSync] Add Google Sync State Progress';
export const RESET_GOOGLE_SYNC_STATE = '[GoogleSync] Reset Google Sync State';
export const FETCH_GOOGLE_SYNC_STATE_FAILURE = '[GoogleSync] Fetch Google Sync State Failure';

export class StartCheckingForGoogleSyncInProgressAction implements Action {
  readonly type = START_CHECKING_FOR_GOOGLE_SYNC_IN_PROGRESS;
  constructor() {}
}

export class PollGoogleSyncStateUntilFinishedAction implements Action {
  readonly type = POLL_GOOGLE_SYNC_STATE_UNTIL_FINISHED;
  constructor() {}
}

export class AddGoogleSyncStateProgressAction implements Action {
  readonly type = ADD_GOOGLE_SYNC_STATE_PROGRESS;
  constructor(public payload: GoogleSyncState) {}
}

export class ResetGoogleSyncStateAction implements Action {
  readonly type = RESET_GOOGLE_SYNC_STATE;
  constructor() {}
}

export class FetchGoogleSyncStateFailureAction implements Action {
  readonly type = FETCH_GOOGLE_SYNC_STATE_FAILURE;
  constructor(public payload: any) {}
}

export type GoogleSyncStateActions =
  | StartCheckingForGoogleSyncInProgressAction
  | PollGoogleSyncStateUntilFinishedAction
  | AddGoogleSyncStateProgressAction
  | ResetGoogleSyncStateAction
  | FetchGoogleSyncStateFailureAction;

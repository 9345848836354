import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { GetCurrentUserAction } from "../../employees/employees.actions";
import { FormControl } from "@angular/forms";
import GIF from "gif.js";

@Component({
  selector: "app-image-processing",
  templateUrl: "./image-processing.component.html",
  styleUrls: ["./image-processing.component.scss"],
})
export class ImageProcessingComponent implements OnInit {
  @Input() imageUploadUrl: string;
  @Input() thumbnailPhotoUrl: string;
  @Input() localAvatar: boolean;

  public imageSrc: string | ArrayBuffer;
  public isImageTooLarge: boolean = false;

  public readonly imageSize = 360;

  public transparentAvatar: Blob | undefined;

  uploadedImage: File = undefined;
  finalImage: File | Blob = undefined;

  croppedImage: string;
  backgroundColor: string = undefined;
  backgroundApplied: boolean = false;

  customBackgroundColor = new FormControl("#");

  borderColorControl = new FormControl("#000000");
  borderWidthControl = new FormControl(10);
  borderRadiusControl = new FormControl(50);

  darkenEffect: boolean = false;

  isImageCropperLoading: boolean = false;

  gifDataUrl: string | null = null;
  isGifGenerating: boolean = false;

  public isSavingGif: boolean = false;
  public isSavingImage: boolean = false;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  constructor(
    private store: Store,
    private http: HttpClient,
    private toast: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.customBackgroundColor.valueChanges.subscribe((value) => {
      if (this.backgroundApplied) {
        this.drawAvatarWithColor(value);
      }
    });
  }

  get loading(): boolean {
    return this.imageSrc && !this.transparentAvatar;
  }

  onFileSelect(event: Event): void {
    this.transparentAvatar = null;
    const element = event.currentTarget as HTMLInputElement;

    if (element.files && element.files.length > 0) {
      const file = element.files[0];

      this.isImageTooLarge = file.size > 5_000_000;

      this.uploadedImage = file;
      this.finalImage = file;
    }
  }

  public async drawAvatarWithColor(color: string) {
    this.isImageCropperLoading = true;
    try {
      if (!this.transparentAvatar) {
        await this.removeImageBackground();
      }
      if (color && color.trim() !== "") {
        this.backgroundColor = color;
        this.finalImage = this.transparentAvatar;
        this.backgroundApplied = true;
      } else {
        this.finalImage = this.transparentAvatar;
        this.backgroundApplied = false;
      }
    } finally {
      this.isImageCropperLoading = false;
    }
  }

  drawOriginalAvatar() {
    this.backgroundColor = undefined;
    this.finalImage = this.uploadedImage;
    this.imageCropper.crop();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  removeImageBackground(): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => (this.imageSrc = reader.result);
      reader.readAsDataURL(this.uploadedImage);

      const form = new FormData();
      form.append("image", this.uploadedImage);

      this.http
        .post(`/image-processing/remove-background`, form, {
          responseType: "blob",
        })
        .subscribe({
          next: (result) => {
            this.finalImage = result;
            this.transparentAvatar = result;
            resolve();
          },
          error: (err) => reject(err),
        });
    });
  }

  onBorderColorChange(event: Event): void {
    const color = (event.target as HTMLInputElement).value;
    this.borderColorControl.setValue(color);
  }

  onCustomColorChange(event: Event): void {
    const color = (event.target as HTMLInputElement).value;
    this.customBackgroundColor.setValue(color);
  }

  async generatePulsatingBorderGif() {
    if (!this.croppedImage) {
      console.error("No cropped image available to generate GIF");
      return;
    }

    this.isGifGenerating = true;
    try {
      const gif = new GIF({
        workers: 2,
        quality: 1,
        width: this.imageSize,
        height: this.imageSize,
        workerScript: "/assets/js/gif.worker.js",
        debug: true,
      });

      const framesCount = 20;
      const croppedImageBlob = await this.base64ToBlob(
        this.croppedImage,
        "image/png"
      );

      for (let i = 0; i < framesCount; i++) {
        const progress = i / framesCount;

        const minOpacity = 0;
        const maxOpacity = 1.0;

        const opacity =
          minOpacity +
          (maxOpacity - minOpacity) *
            (0.5 + 0.5 * Math.sin(progress * Math.PI * 2));
        const modifiedColor = this.adjustColorOpacity(
          this.borderColorControl.value,
          opacity
        );

        const darkenEffect = this.darkenEffect
          ? 0.8 + 0.2 * Math.sin(progress * Math.PI * 2)
          : 1;

        const framedImage = await this.applyEffectsToImage(
          croppedImageBlob,
          modifiedColor,
          this.borderWidthControl.value,
          true,
          darkenEffect
        );

        const frameCanvas = await this.blobToCanvas(framedImage);
        gif.addFrame(frameCanvas, { delay: 100 });
      }

      gif.on("finished", (blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.gifDataUrl = reader.result as string;
          this.isGifGenerating = false;
        };
        reader.readAsDataURL(blob);
      });

      gif.render();
    } catch (error) {
      console.error("Error while generating GIF:", error);
      this.isGifGenerating = false;
    }
  }

  private async applyEffectsToImage(
    image: Blob,
    borderColor: string,
    borderWidth: number,
    enabled: boolean,
    darkenFactor: number
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = async () => {
        try {
          const size = this.imageSize;

          const canvas = document.createElement("canvas");
          canvas.width = size;
          canvas.height = size;
          const ctx = canvas.getContext("2d", { willReadFrequently: true });
          if (!ctx) {
            reject("Failed to get 2D context");
            return;
          }

          ctx.imageSmoothingEnabled = true;

          ctx.fillStyle = "#FFFFFF";
          ctx.fillRect(0, 0, size, size);

          const radiusPercent = this.borderRadiusControl.value;
          const radiusPx = (radiusPercent / 100) * (size / 2);
          const isCircle = radiusPercent === 50;

          ctx.beginPath();
          if (isCircle) {
            const centerX = size / 2;
            const centerY = size / 2;
            const radius = size / 2;
            ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
          } else {
            const w = size;
            const h = size;
            const r = radiusPx;
            ctx.moveTo(r, 0);
            ctx.lineTo(w - r, 0);
            ctx.quadraticCurveTo(w, 0, w, r);
            ctx.lineTo(w, h - r);
            ctx.quadraticCurveTo(w, h, w - r, h);
            ctx.lineTo(r, h);
            ctx.quadraticCurveTo(0, h, 0, h - r);
            ctx.lineTo(0, r);
            ctx.quadraticCurveTo(0, 0, r, 0);
          }

          ctx.closePath();
          ctx.clip();

          ctx.drawImage(img, 0, 0, size, size);

          if (darkenFactor < 1) {
            ctx.fillStyle = `rgba(0, 0, 0, ${1 - darkenFactor})`;
            ctx.fillRect(0, 0, size, size);
          }

          if (enabled) {
            ctx.beginPath();
            const inset = borderWidth / 2;

            if (isCircle) {
              const centerX = size / 2;
              const centerY = size / 2;
              const radius = size / 2 - inset;
              ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
            } else {
              const w = size;
              const h = size;
              const r = radiusPx - inset;
              if (r < 0) {
                ctx.rect(0, 0, size, size);
              } else {
                ctx.moveTo(r + inset, inset);
                ctx.lineTo(w - r - inset, inset);
                ctx.quadraticCurveTo(w - inset, inset, w - inset, r + inset);
                ctx.lineTo(w - inset, h - r - inset);
                ctx.quadraticCurveTo(
                  w - inset,
                  h - inset,
                  w - r - inset,
                  h - inset
                );
                ctx.lineTo(r + inset, h - inset);
                ctx.quadraticCurveTo(inset, h - inset, inset, h - r - inset);
                ctx.lineTo(inset, r + inset);
                ctx.quadraticCurveTo(inset, inset, r + inset, inset);
              }
            }

            ctx.closePath();
            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.stroke();
          }

          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject("Error generating bordered image");
            }
          }, "image/png");
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = () => {
        reject("Failed to load image");
      };

      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result as string;
      };
      reader.onerror = () => {
        reject("Failed to read the image file");
      };
      reader.readAsDataURL(image);
    });
  }

  private base64ToBlob(base64: string, contentType: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      try {
        const byteString = atob(base64.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }

        resolve(new Blob([arrayBuffer], { type: contentType }));
      } catch (error) {
        reject("Failed to convert base64 to Blob");
      }
    });
  }

  private adjustColorOpacity(hexColor: string, opacity: number): string {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  private blobToCanvas(blob: Blob): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(blob);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = this.imageSize;
        canvas.height = this.imageSize;
        const ctx = canvas.getContext("2d", { willReadFrequently: true });
        if (!ctx) {
          reject("Failed to get 2D context");
          return;
        }
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        URL.revokeObjectURL(url);
        resolve(canvas);
      };
      img.onerror = () => {
        reject("Failed to load image for blobToCanvas");
      };
      img.src = url;
    });
  }

  saveGifImage() {
    if (!this.gifDataUrl) return;

    this.isSavingGif = true;
    this.http
      .put(this.imageUploadUrl, {
        avatarBase64: this.gifDataUrl,
        isGif: true,
      })
      .subscribe({
        next: () => {
          this.toast.success(
            this.translate.instant("employee.avatar.saveSuccess")
          );
          this.store.dispatch(new GetCurrentUserAction());
        },
        error: () => {
          this.toast.error(this.translate.instant("employee.avatar.saveError"));
        },
        complete: () => {
          this.isSavingGif = false;
        },
      });
  }

  saveNewImage() {
    this.isSavingImage = true;
    this.http
      .put(this.imageUploadUrl, {
        avatarBase64: this.croppedImage,
        isGif: false,
      })
      .subscribe({
        next: () => {
          this.toast.success(
            this.translate.instant("employee.avatar.saveSuccess")
          );
          this.store.dispatch(new GetCurrentUserAction());
        },
        error: () => {
          this.toast.error(this.translate.instant("employee.avatar.saveError"));
        },
        complete: () => {
          this.isSavingImage = false;
        },
      });
  }
}

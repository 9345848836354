import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "formatDuration", pure: false })
export class FormatDurationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  private getPluralCategory(value: number): string {
    const currentLang = this.translate.currentLang;
    if (currentLang === "pl") {
      if (value === 1) {
        return "one";
      }
      if (
        value % 10 >= 2 &&
        value % 10 <= 4 &&
        (value % 100 < 10 || value % 100 >= 20)
      ) {
        return "few";
      }
      return "many";
    } else {
      return value === 1 ? "one" : "other";
    }
  }

  transform(totalSeconds: number): string {
    if (totalSeconds == null || isNaN(totalSeconds)) {
      return this.translate.instant(
        "formatDuration.seconds." + this.getPluralCategory(0),
        { count: 0 }
      );
    }
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let result = "";
    if (hours > 0) {
      result +=
        this.translate.instant(
          "formatDuration.hours." + this.getPluralCategory(hours),
          { count: hours }
        ) + " ";
      result +=
        this.translate.instant(
          "formatDuration.minutes." + this.getPluralCategory(minutes),
          { count: minutes }
        ) + " ";
      result += this.translate.instant(
        "formatDuration.seconds." + this.getPluralCategory(seconds),
        { count: seconds }
      );
    } else if (minutes > 0) {
      result +=
        this.translate.instant(
          "formatDuration.minutes." + this.getPluralCategory(minutes),
          { count: minutes }
        ) + " ";
      result += this.translate.instant(
        "formatDuration.seconds." + this.getPluralCategory(seconds),
        { count: seconds }
      );
    } else {
      result += this.translate.instant(
        "formatDuration.seconds." + this.getPluralCategory(seconds),
        { count: seconds }
      );
    }

    return result;
  }
}

import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignatureInstallationState } from '../signature-installation-state.interface';
import { getCurrentStateSelector } from '../signature-installation-state.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SignatureInstallationErrorDialogComponent } from '../signature-installation-error-dialog/signature-installation-error-dialog.component';
import moment from 'moment';
import { MY_FORMATS } from '../../shared/shared.module';

@Component({
  selector: 'app-signature-installation-progress-toast',
  templateUrl: './signature-installation-progress-toast.component.html',
  styleUrls: ['./signature-installation-progress-toast.component.scss']
})
export class SignatureInstallationProgressToastComponent implements AfterViewInit {
  @ViewChild('snackbarTemplate') template: TemplateRef<any>;
  public isOpen = false;
  
  private currentProcessId: string = null;

  public installedEmployeesCount = 0;
  public installedAliasesCount = 0;

  public totalInstallations = 0;
  
  public currentState: SignatureInstallationState;
  public lastUpdate: string;

  constructor(private readonly store: Store,
              private readonly dialog: MatDialog,
              private readonly snackbar: MatSnackBar) {
  }

  ngAfterViewInit(): void {
    this.store.select(getCurrentStateSelector).subscribe(result => {
      if (!result) {
        this.currentState = null;
        this.currentProcessId = null;
        this.installedEmployeesCount = 0;
        this.installedAliasesCount = 0;
        if (this.isOpen) {
          this.isOpen = false;
          this.snackbar.dismiss();
        }
        return;
      }

      if (!this.currentProcessId || this.currentProcessId !== result.id) {
        this.currentProcessId = result.id;
        this.installedEmployeesCount = 0;
        this.installedAliasesCount = 0;
      }
      
      this.currentState = result;
      this.lastUpdate = moment(new Date()).format(MY_FORMATS.display.time);

      const newEmployeesCount = result.installations.filter(i => !i.isAlias).length;
      const newAliasesCount = result.installations.filter(i => i.isAlias).length;
      this.totalInstallations = (result.totalEmployees || 0) + (result.totalAliases || 0);

      if (result.status !== 'IN_PROGRESS') {
        this.installedEmployeesCount = newEmployeesCount;
        this.installedAliasesCount = newAliasesCount;
      } else {
        if (newEmployeesCount !== this.installedEmployeesCount) {
          this.animateCounter('installedEmployeesCount', this.installedEmployeesCount, newEmployeesCount, 500);
        }
        if (newAliasesCount !== this.installedAliasesCount) {
          this.animateCounter('installedAliasesCount', this.installedAliasesCount, newAliasesCount, 500);
        }
      }

      if (!this.isOpen && result) {
        this.isOpen = true;
        this.snackbar.openFromTemplate(this.template);
        return;
      }

      if (!result) {
        this.isOpen = false;
        this.snackbar.dismiss();
      }
    });
  }

  private animateCounter(property: 'installedEmployeesCount' | 'installedAliasesCount', from: number, to: number, duration: number = 500): void {
    const steps = 20;
    const stepTime = duration / steps;
    const increment = (to - from) / steps;
    let currentStep = 0;
    const intervalId = setInterval(() => {
      currentStep++;
      this[property] = Math.round(from + increment * currentStep);
      if (currentStep >= steps) {
        clearInterval(intervalId);
        this[property] = to;
      }
    }, stepTime);
  }

  get errors(): SignatureInstallationState['installations'] {
    return this.currentState.installations.filter(i => i.error);
  }

  close() {
    this.isOpen = false;
    this.snackbar.dismiss();
  }

  showErrors() {
    this.isOpen = false;
    this.snackbar.dismiss();
    this.dialog.open(SignatureInstallationErrorDialogComponent, { data: this.currentState });
  }
}

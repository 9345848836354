import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { LocalizedDatePipe } from "./localized-date.pipe";
import { FormatDurationPipe } from "./format-duration.pipe";
import { HttpLoaderFactory } from "../app.module";
import { MatRadioModule } from "@angular/material/radio";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { LoadingComponent } from "./loading/loading.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component";
import { CommonModule } from "@angular/common";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ImageProcessingComponent } from "./image-processing/image-processing.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ProgressButtonComponent } from "./progress-button/progress-button.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ClientTableComponent } from "./client-table/client-table.component";
import { ClosablePanelComponent } from "./closable-panel/closable-panel.component";
import { AvatarUploaderComponent } from "./avatar-uploader/avatar-uploader.component";
import { HandwrittenSignatureComponent } from "./handwritten-signature/handwritten-signature.component";
// import {MatSelectFilterModule} from 'mat-select-filter';
import { TrialInfoComponent } from "./trial-info/trial-info.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ReactiveFormsModule } from "@angular/forms";
import { PartnerAdminTableComponent } from "./partner-admin-table/partner-admin-table.component";
import { FormsModule } from "@angular/forms";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
    time: "HH:mm:ss",
  },
};

const pipes = [LocalizedDatePipe, FormatDurationPipe, SafeHtmlPipe];

const components = [
  LoadingComponent,
  ImageUploaderComponent,
  ImageProcessingComponent,
  ProgressButtonComponent,
  ClientTableComponent,
  PartnerAdminTableComponent,
  AvatarUploaderComponent,
  HandwrittenSignatureComponent,
  TrialInfoComponent,
];

const materialModules = [
  MatMenuModule,
  MatIconModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatDividerModule,
  MatCheckboxModule,
  MatSortModule,
  MatPaginatorModule,
  MatTableModule,
  MatInputModule,
  MatRadioModule,
  MatDialogModule,
  MatCardModule,
  MatTabsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatSelectModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatTooltipModule,
  ImageCropperModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  // MatSelectFilterModule,
];

@NgModule({
  imports: [
    ...materialModules,
    AngularEditorModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [...pipes, ...components, ClosablePanelComponent],
  exports: [
    ...pipes,
    ...components,
    ...materialModules,
    TranslateModule,
    CommonModule,
    AngularEditorModule,
    ClosablePanelComponent,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SharedModule {}

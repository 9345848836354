import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  AddClientRequest,
  ClientWithStats,
  EditClientRequest,
} from "./client.interface";
import { endOfDay } from "../shared/utils/trial.util";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  constructor(private http: HttpClient) { }

  getAll = () => this.http.get<ClientWithStats[]>("/admin/client");

  add = (request: AddClientRequest) =>
    this.http.post("/admin/client", {
      ...request,
      trialFinishDate: endOfDay(request.trialFinishDate),
    });

  remove = (domain: string) =>
    this.http.delete(`/admin/client/${encodeURIComponent(domain)}`);

  edit = (domain: string, request: EditClientRequest) =>
    this.http.put(`/admin/client/${domain}`, {
      ...request,
      trialFinishDate: endOfDay(request.trialFinishDate),
    });

  updateLanguage(domain: string, language: string) {
    return this.http.patch(
      `/admin/client/${encodeURIComponent(domain)}/language`,
      { language }
    );
  }

  syncAllDealsWithPipedrive(): Observable<any> {
    return this.http.post("/billing-aggregated-stats/sync-all", {});
  }
}

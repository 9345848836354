import { UntypedFormControl } from '@angular/forms';

export const validatePhoneNumber = (formControl: UntypedFormControl) => {

  const valueWithoutSpaces = formControl.value ? formControl.value.replace(/\s/g, '') : '';
  return new RegExp('^\\+?\\d+$').test(valueWithoutSpaces)
    ? null
    : { phoneNumberInvalid: true };
};

export const validateNonRequiredPhoneNumber = (formControl: UntypedFormControl) =>
  !formControl.value ? null : validatePhoneNumber(formControl);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GoogleSyncStateService } from './google-sync-state.service';
import { switchMap, takeUntil, filter, map, catchError } from 'rxjs/operators';
import {
  POLL_GOOGLE_SYNC_STATE_UNTIL_FINISHED,
  PollGoogleSyncStateUntilFinishedAction,
  START_CHECKING_FOR_GOOGLE_SYNC_IN_PROGRESS,
  AddGoogleSyncStateProgressAction,
  ResetGoogleSyncStateAction,
  ADD_GOOGLE_SYNC_STATE_PROGRESS,
  FetchGoogleSyncStateFailureAction,
} from './google-sync-state.actions';
import { Subject, timer, concat, of } from 'rxjs';
import moment from 'moment';

@Injectable()
export class GoogleSyncStateEffects {
  startCheckingForGoogleSyncInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(START_CHECKING_FOR_GOOGLE_SYNC_IN_PROGRESS),
      switchMap(() => {
        const reset$ = of(new ResetGoogleSyncStateAction());
        const initialTime = new Date();
        const kill$ = new Subject();
        const polling$ = timer(500, 1000).pipe(
          takeUntil(kill$),
          switchMap(() =>
            this.googleSyncStateService.getInProgressState().pipe(
              switchMap(response => {
                if (!Array.isArray(response) || !response.length) {
                  return [];
                }
                const latest = response[0];

                if (
                  latest.status === 'IN_PROGRESS' ||
                  (latest.modifiedAt && moment(latest.modifiedAt).isAfter(initialTime))
                ) {
                  kill$.next(1);
                  return [
                    new PollGoogleSyncStateUntilFinishedAction(),
                    new AddGoogleSyncStateProgressAction(latest),
                  ];
                }
                return [];
              }),
              catchError(error => {
                kill$.next(1);
                return of(new FetchGoogleSyncStateFailureAction(error));
              })
            )
          )
        );

        return concat(reset$, polling$);
      })
    )
  );

  pollGoogleSyncStateUntilFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POLL_GOOGLE_SYNC_STATE_UNTIL_FINISHED),
      switchMap(() => {
        const kill$ = new Subject();
        return timer(2000, 2000).pipe(
          takeUntil(kill$),
          switchMap(() =>
            this.googleSyncStateService.getInProgressState().pipe(
              switchMap(response => {
                const latest = response?.[0];
                if (!latest) {
                  kill$.next(1);
                  return [];
                }
                if (latest.status !== 'IN_PROGRESS') {
                  kill$.next(1);
                }
                return [new AddGoogleSyncStateProgressAction(latest)];
              }),
              catchError(error => {
                kill$.next(1);
                return of(new FetchGoogleSyncStateFailureAction(error));
              })
            )
          )
        );
      })
    )
  );

  finishGoogleSync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_GOOGLE_SYNC_STATE_PROGRESS),
      filter((action: AddGoogleSyncStateProgressAction) => action.payload.status !== 'IN_PROGRESS'),
      switchMap(() =>
        timer(2000).pipe(
          switchMap(() => this.googleSyncStateService.getInProgressState()),
          map(response => response[0]),
          switchMap(latest => {
            if (latest?.status === 'IN_PROGRESS') {
              return of(new AddGoogleSyncStateProgressAction(latest));
            } else {
              return timer(7000).pipe(map(() => new ResetGoogleSyncStateAction()));
            }
          }),
          catchError(error => {
            return of(new FetchGoogleSyncStateFailureAction(error));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private googleSyncStateService: GoogleSyncStateService
  ) {}
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  BillingSettings,
  BillingMonthlyStats,
  BillingYearlyStats,
  BillingAggregatedStats
} from "../../settings/settings.interface";

@Injectable({
  providedIn: "root",
})
export class BillingSettingsService {
  constructor(private http: HttpClient) { }

  get(): Observable<BillingSettings> {
    return this.http.get<BillingSettings>("/billing-settings");
  }

  update(payload: BillingSettings): Observable<any> {
    return this.http.post("/billing-settings", payload);
  }

  updatePaymentMethod(paymentMethod: string): Observable<any> {
    return this.http.post("/billing-settings/payment-method", { paymentMethod });
  }

  updateSubdomains(domain: string, subdomains: Array<{ domain: string }>): Observable<any> {
    return this.http.post(`/billing-settings/subdomains`, { domain, subdomains });
  }

  getAllDomains(): Observable<string[]> {
    return this.http.get<string[]>("/admin/client/all-domains");
  }

  getMonthlyStats(domain: string): Observable<BillingMonthlyStats[]> {
    return this.http.get<BillingMonthlyStats[]>(`/billing-monthly-stats/${domain}`);
  }

  getAggregatedMonthlyStats(domain: string): Observable<BillingAggregatedStats[]> {
    return this.http.get<BillingAggregatedStats[]>(`/billing-aggregated-stats/${domain}`);
  }

  getYearlyStats(domain: string): Observable<BillingYearlyStats[]> {
    return this.http.get<BillingYearlyStats[]>(`/billing-yearly-stats/${domain}`);
  }

  validateBillingInfo(): Observable<{ success: boolean; missingFields?: string[] }> {
    return this.http.get<{ success: boolean; missingFields?: string[] }>("/billing-settings/validate-billing-info");
  }

  createCheckoutSession(flowType: "billing" | "expired" | "yearly" = "billing"): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(`/stripe/create-checkout-session`, { flowType });
  }

  verifyCheckoutSession(sessionId: string): Observable<any> {
    return this.http.post("/stripe/verify-checkout-session", { sessionId });
  }
}

import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {
  AddSignatureObject,
  ChangeSignatureObject,
  Signature,
  SaveSignatureRequest,
  Template,
  SignaturePredefined, SaveTemplatePredefined
} from './signatures.interface';
import {Planned} from '../planned/planned';

export const wyswigConfig = {editable: true, sanitize: false, toolbarHiddenButtons: [['insertImage', 'insertVideo']]};
export const codeEditorOptions = {
  mode: 'htmlmixed',
  lineNumbers: true,
  theme: 'default',
  autoCloseBrackets: true,
  autoCloseTags: true,
  tabSize: 2,
  lineWrapping: true,
  autoRefresh: true,
};

@Injectable({providedIn: 'root'})
export class SignaturesService {

  constructor(private http: HttpClient) {
  }

  getSignatures = (): Observable<Signature[]> => {
    return this.http.get<Signature[]>('/template');
  };

  changeSignature = (payload: ChangeSignatureObject): Observable<any> => {
    console.log(payload);
    return of({});
  };

  removeSignature = (id: string): Observable<any> => {
    return this.http.delete(`/template/${id}`);
  };

  duplicateSignature = (id: string): Observable<any> => {
    return this.http.post(`/template/${id}/duplicate`, {});
  };

  installSignature = (body): Observable<any> => {
    return this.http.post('/footer', body);
  };

  uninstallSignature = (id: string): Observable<any> => {
    console.log(id);
    return of({});
  };

  reinstallSignatures = (): Observable<any> => {
    return this.http.post('/template/reinstall', {});
  };

  setReinstallSchedule(enabled: boolean, time: string, domain: string): Observable<any> {
    return this.http.post('/schedule/reinstall', { enabled, time, domain });
  }
  
  getAll = () => this.http.get<Template[]>('/template');
  save = (data: any) => this.http.post('/template', data);
  setDefaultForNewEmployee = (id: string) => this.http.post('/template/default-for-new-employee', {templateId: id});
  byId = (id: string) => this.http.get(`/template/${id}`);
  delete = (id: string) => this.http.delete(`/template/${id}`);
  placeholders = () => this.http.get(`/template/placeholders`);
  update = (id: string, content: SaveSignatureRequest, installSignature: boolean) => this.http.put(`/template/${id}`, {
    ...content,
    installSignature
  });
  predefined = () => this.http.get<SignaturePredefined[]>(`/predefined/`);

  getScheduled = () => this.http.get<Planned[]>(`/schedule`);
  removeScheduled = (id: string) => this.http.delete(`/schedule/${id}`);
  setFooter = (body: any) => this.http.post(`/schedule/template`, body);


  getPredefined = () => this.http.get<SignaturePredefined[]>('/predefined');
  addPredefined = (predefined: SaveTemplatePredefined): Observable<SignaturePredefined> => this.http.post<SignaturePredefined>('/admin/predefined', predefined);
  predefinedById = (id: string): Observable<SignaturePredefined> => this.http.get<SignaturePredefined>(`/predefined/${id}`);
  updatePredefined = (id: string, content: SaveTemplatePredefined) => this.http.put(`/admin/predefined/${id}`, content);
  copyPredefinedToClient = (id: string) => this.http.post<{ clientSignatureId: string }>(`/predefined/${id}/copy`, {});
  deletePredefined = (id: string) => this.http.delete(`/admin/predefined/${id}`);
  reorderPredefined = (idsOrder: string[]) => this.http.put(`/admin/predefined`, idsOrder);

  getClientPlanId(): Observable<{ planId: string; isFreePlan: boolean }> {
    return this.http.get<{ planId: string; isFreePlan: boolean }>('/template/plan-id');
  }  
}

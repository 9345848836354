<mat-toolbar class="header signatures-header">
  <div class="action">
    <span>{{'menu.signatures.custom' | translate}}</span>
  </div>
  <a mat-raised-button color="primary" [routerLink]="['create']">{{'signatures.new' | translate}}</a>
</mat-toolbar>

<div class="signatures-container" *ngIf="signatures$">
  <div *ngFor="let signature of signatures$ | async">
    <div class="signature-wrapper" [id]="signature.id">
      <div class="signature-header">
        <div class="name-default-wrapper">
          <h4>{{signature.name}}</h4>

          <button
            *ngIf="!defaultSignatureSet"
            (click)="setDefault(signature)"
            class="chip-button"
            [title]="(!auth.lastGoogleSync ? 'signatures.default.setDefaultDisabledTooltip' : 'signatures.default.setDefaultTooltip') | translate"
            [disabled]="!auth.lastGoogleSync"
          >
            {{'signatures.default.setDefaultButton' | translate}}
          </button>

          <button
            *ngIf="signature.defaultForNewEmployee"
            (click)="removeDefault(signature)"
            class="chip-button" [title]="'signatures.default.unsetDefaultTooltip' | translate">
            <mat-icon>check</mat-icon>
            <span>{{'signatures.default.unsetDefaultButton' | translate}}</span>
          </button>
        </div>

        <div class="signature-installed">
          <ng-container *ngIf="(signature.numberOfInstallations > 0 || signature.numberOfAliases > 0) || (signature.numberOfInternalInstallations > 0 || signature.numberOfInternalAliases > 0); else notInstalled">

            <div *ngIf="signature.numberOfInstallations > 0 || signature.numberOfAliases > 0" class="signature-row">
              <div class="signature-label-column" matTooltip="{{ 'externalSignature' | translate }}">
                <mat-icon aria-label="external">launch</mat-icon>
              </div>
              <div class="signature-name-column">
                <ng-container>
                  <a
                    [routerLink]="['/employees']"
                    [queryParams]="{ signatureTemplateId: signature.id }"
                    *ngIf="signature.numberOfInstallations > 0">
                    <span class="chip installed-text" disabled>
                      {{ 'signatures.installed' | translate: { installed: signature.numberOfInstallations } }}
                    </span>
                  </a>
                  <a
                    [routerLink]="['/employees']"
                    [queryParams]="{ signatureTemplateId: signature.id, aliases: true }"
                    *ngIf="signature.numberOfAliases > 0">
                    <span class="chip aliases-installed-text" disabled>
                      {{ 'signatures.aliasesInstalled' | translate: { installed: signature.numberOfAliases } }}
                    </span>
                  </a>
                </ng-container>
              </div>
              <div class="signature-close-column"></div>
            </div>

            <div *ngIf="signature.numberOfInternalInstallations > 0 || signature.numberOfInternalAliases > 0" class="signature-row">
              <div class="signature-label-column" matTooltip="{{ 'internalSignature' | translate }}">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" fill="currentColor">
                  <title>{{ 'signatures.internalInstallationsTooltip' | translate }}</title>
                  <path d="M19 19H5V5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7z"/>
                  <path d="M16 15v-2h-3.59L20 5.41l-1.41-1.41L11 11.59V8h-2v7h7z" />
                </svg>
              </div>              
              <div class="signature-name-column">
                <ng-container>
                  <a
                    [routerLink]="['/employees']"
                    [queryParams]="{ signatureTemplateId: signature.id }"
                    *ngIf="signature.numberOfInternalInstallations > 0">
                    <span class="chip installed-text" disabled>
                      {{ 'signatures.installed' | translate: { installed: signature.numberOfInternalInstallations } }}
                    </span>
                  </a>
                  <a
                    [routerLink]="['/employees']"
                    [queryParams]="{ signatureTemplateId: signature.id, aliases: true }"
                    *ngIf="signature.numberOfInternalAliases > 0">
                    <span class="chip aliases-installed-text" disabled>
                      {{ 'signatures.aliasesInstalled' | translate: { installed: signature.numberOfInternalAliases } }}
                    </span>
                  </a>
                </ng-container>
              </div>
              <div class="signature-close-column"></div>
            </div>

          </ng-container>

          <ng-template #notInstalled>
            <span class="chip not-installed-text" disabled>
              {{ 'signatures.notInstalled' | translate }}
            </span>
          </ng-template>
        </div>

      </div>
      <div class="signature-content-wrapper">
        <div [innerHTML]="signature.content | safeHtml" class="signature-content"></div>
      </div>
      <div class="signature-footer">
        <div class="signature-addons">
          <div>
            <mat-icon>{{signature.addonLinkId ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
            {{'signatures.link' | translate}}</div>
          <div>
            <mat-icon>{{signature.addonBannerId || signature.addonAdditionalBannerId  ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
            {{'signatures.banner' | translate}}</div>
          <div>
            <mat-icon>{{signature.addonDisclaimerId ? 'check_box' : 'indeterminate_check_box'}}</mat-icon>
            {{'signatures.disclaimer' | translate}}</div>
        </div>
        <div class="signature-install">
          <a mat-raised-button color="primary" (click)="goToDetailsSignature(signature.id)">
            {{ 'install' | translate }}
          </a>
        </div>
      </div>
    </div>
    <div class="signature-actions">
      <mat-icon title="{{'edit' | translate}}" (click)="goToEditSignature(signature.id)">edit</mat-icon>
      <mat-icon title="{{'duplicate' | translate}}" (click)="duplicateSignature(signature.id)">file_copy</mat-icon>
      <mat-icon title="{{'delete' | translate}}" (click)="removeSignature(signature.id)" *ngIf="!signature.numberOfInstallations">delete_outline</mat-icon>
      <mat-icon title="{{'copyHTMLToClipboard' | translate}}" (click)="copySignatureToClipboard(signature.content)">
        content_copy
      </mat-icon>
    </div>
  </div>
</div>

<div class="footer-actions" *ngIf="(signatures$ | async)?.length > 0">
  <a mat-raised-button color="primary" (click)="reinstallSignatures()">
    {{ 'signatures.reinstall' | translate }}
  </a>
</div>

import { Component, OnInit } from "@angular/core";
import { ApiSettingsService, ApiToken } from "./api-settings.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { CreateTokenDialogComponent } from "./create-token/create-token-dialog.component";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-api-settings",
  templateUrl: "./api-settings.component.html",
  styleUrls: ["./api-settings.component.scss"],
})
export class ApiSettingsComponent implements OnInit {
  tokens: ApiToken[] = [];
  displayedColumns: string[] = [
    "name",
    "createdAt",
    "expiresAt",
    "status",
    "actions",
  ];
  dataSource = new MatTableDataSource<ApiToken>();
  isLoading = true;

  constructor(
    private apiSettingsService: ApiSettingsService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadTokens();
  }

  loadTokens() {
    this.isLoading = true;
    this.apiSettingsService.getTokens().subscribe({
      next: (tokens) => {
        this.tokens = tokens;
        this.dataSource.data = tokens;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  openCreateTokenDialog() {
    const dialogRef = this.dialog.open(CreateTokenDialogComponent);

    const dialogComponentInstance = dialogRef.componentInstance;

    dialogComponentInstance.tokenCreated$.subscribe((newToken: ApiToken) => {
      this.tokens.push(newToken);
      this.dataSource.data = [...this.tokens];
    });
  }

  deleteToken(tokenId: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.translate.instant("settings.api.deleteTokenConfirmation"),
        message: this.translate.instant("settings.api.deleteTokenMessage"),
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.apiSettingsService.deleteToken(tokenId).subscribe({
          next: () => {
            this.tokens = this.tokens.filter((token) => token.id !== tokenId);
            this.dataSource.data = [...this.tokens];
            this.toastr.success(
              this.translate.instant("settings.api.deleteSuccess")
            );
          },
          error: () => {
            this.toastr.error(
              this.translate.instant("settings.api.deleteError")
            );
          },
        });
      }
    });
  }

  extendToken(tokenId: string) {
    this.apiSettingsService.extendToken(tokenId).subscribe({
      next: (updatedToken) => {
        const index = this.tokens.findIndex((t) => t.id === tokenId);
        if (index !== -1) {
          this.tokens[index] = updatedToken;
          this.dataSource.data = [...this.tokens];
        }
        this.toastr.success(
          this.translate.instant("settings.api.extendSuccess")
        );
      },
      error: () => {
        this.toastr.error(this.translate.instant("settings.api.extendError"));
      },
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { GoogleSyncProgressToastComponent } from './google-sync-progress-toast.component/google-sync-progress-toast.component';
import { GoogleSyncErrorDialogComponent } from './google-sync-error-dialog.component/google-sync-error-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { GoogleSyncStateEffects } from './google-sync-state.effects';
import { StoreModule } from '@ngrx/store';
import { googleSyncStateReducer } from './google-sync-state.reducer';

@NgModule({
  declarations: [GoogleSyncProgressToastComponent, GoogleSyncErrorDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    EffectsModule.forFeature([GoogleSyncStateEffects]),
    StoreModule.forFeature('googleSyncState', googleSyncStateReducer),
  ],
  exports: [GoogleSyncProgressToastComponent],
})
export class GoogleSyncStateModule {}

import { SignatureInstallationStateStore } from './signature-installation-state.interface';
import {
  ADD_INSTALLATION_STATE_PROGRESS,
  RESET_INSTALLATION_STATE,
  SignatureInstallationStateActions,
} from './signature-installation-state.actions';
import { createSelector } from '@ngrx/store';

export const initialState: SignatureInstallationStateStore = {};

export function signatureInstallationStateReducer(
  state: SignatureInstallationStateStore = initialState,
  action: SignatureInstallationStateActions
): SignatureInstallationStateStore {
  switch (action.type) {
    case ADD_INSTALLATION_STATE_PROGRESS:
      return { ...state, currentState: action.payload };
    case RESET_INSTALLATION_STATE:
      return initialState;
    default:
      return state;
  }
}

export const getSignatureInstallationStateRootState = (state) => state.signatureInstallationState;
export const getCurrentStateState = (state: SignatureInstallationStateStore) => state.currentState;

export const getCurrentStateSelector = createSelector(getSignatureInstallationStateRootState, getCurrentStateState);

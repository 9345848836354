<div class="success-container">
  <div class="checkmark-circle">
    <div class="background"></div>
    <div class="checkmark"></div>
  </div>
  <h3>{{ 'trial.expired.success.paymentSuccess' | translate }}</h3>
  <span>{{ 'trial.expired.success.thankYouPayment' | translate }}</span>
  <button mat-raised-button color="primary" type="button" (click)="backToApp()">
    {{ 'trial.expired.success.close' | translate }}
  </button>
</div>

import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  User as FirebaseUser,
  Auth,
  authState,
  user,
  signInWithRedirect,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  signOut,
  signInWithEmailLink,
  getRedirectResult,
  idToken,
  signInWithPopup,
} from "@angular/fire/auth";
import { UserService } from "../users/users.service";
import { User } from "../users/user";
import { Store } from "@ngrx/store";
import { ResetAccountAction } from "../accounts/accounts.actions";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { getCurrentAccount } from "src/app/accounts/accounts.reducer";
import { ChangeAccountAction } from "../accounts/accounts.actions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { take } from "rxjs";
import { environment } from "src/environments/environment";

const UTM_KEY = "utm";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private sessionStart: Date | null = null;
  private activeDuration: number = 0;
  private sessionActiveStart: Date | null = null;
  private idleTimer: any;
  public loginReported = false;
  currentDomain: string | null = null;
  user$ = user(this.auth);
  idToken$ = idToken(this.auth);
  idToken;
  authState$ = authState(this.auth);
  authState;
  appUser: User | undefined;
  hasCompanyManagerAccess = false;
  canChangeAccounts = false;
  isSuperAdmin = false;
  isCompanyManager = false;
  isPartner = false;
  isGoogleAdmin = false;
  trialFinishDate;
  lastGoogleSync;
  localData;

  constructor(
    public router: Router,
    private http: HttpClient,
    private userService: UserService,
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.idToken$.subscribe((token: string | null) => {
      this.idToken = token;
    });
    this.authState$.subscribe((aUser: FirebaseUser | null) => {
      this.authState = aUser;
    });
    this.store.select(getCurrentAccount).subscribe((domain) => {
      this.currentDomain = domain;
    });
    this.storeUTMParams();

    this.sessionStart = new Date();
    this.sessionActiveStart = new Date();
    this.setupActivityListeners();

    window.addEventListener("beforeunload", () => this.reportSession());
  }

  private setupActivityListeners(): void {
    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
    events.forEach(event =>
      window.addEventListener(event, () => this.resetIdleTimer())
    );

    this.resetIdleTimer();
  }

  private resetIdleTimer(): void {
    if (!this.sessionActiveStart) {
      this.sessionActiveStart = new Date();
    }

    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
    }

    this.idleTimer = setTimeout(() => {
      if (this.sessionActiveStart) {
        const now = new Date();
        this.activeDuration += now.getTime() - this.sessionActiveStart.getTime();
        this.sessionActiveStart = null;
      }
    }, 30000);
  }

  async reportSession(): Promise<void> {
    if (!this.sessionStart) {
      return;
    }
    if (this.sessionActiveStart) {
      const now = new Date();
      this.activeDuration += now.getTime() - this.sessionActiveStart.getTime();
      this.sessionActiveStart = null;
    }
    const sessionEnd = new Date();
    const payload = {
      email: this.appUser?.email,
      domain: this.currentDomain,
      sessionStart: this.sessionStart.toISOString(),
      sessionEnd: sessionEnd.toISOString(),
      activeDuration: Math.floor(this.activeDuration / 1000),
    };

    const url = `${environment.apiUrl}/user-activity/report`;
    const blob = new Blob([JSON.stringify(payload)], {
      type: "application/json",
    });

    navigator.sendBeacon(url, blob);

    this.sessionStart = null;
  }

  async sendSignWithEmail(email: string, lang: string): Promise<void> {
    try {
      const utm = this.getUTMParams();
      const response: any = await this.http
        .post("/auth/sign-with-email", { email, lang, utm })
        .toPromise();

      if (!response.userExists) {
        this.toastr.info(
          this.translate.instant("login.userNotFound"),
          "",
          { timeOut: 3000 }
        );
        setTimeout(() => {
          window.location.href = "https://gsignature.com/sign-up";
        }, 3000);
      } else {
        this.toastr.success(this.translate.instant("login.emailSentSuccess"));
      }
    } catch (error) {
      console.error("Error sending email link:", error);
      this.toastr.error(this.translate.instant("login.emailSentError"));
      throw error;
    }
  }

  public async loginWithEmailLink(): Promise<boolean> {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");

    if (email && isSignInWithEmailLink(this.auth, window.location.href)) {
      try {
        await signInWithEmailLink(this.auth, email, window.location.href);
        return true;
      } catch (error) {
        console.error("Error signing in with email link:", error);
        return false;
      }
    }
    return false;
  }

  public async loginWithGoogle() {
    if (window.location.hostname === "localhost") {
      await signInWithPopup(this.auth, new GoogleAuthProvider());
    } else {
      window.history.replaceState({}, "", "/login/callback");
      await signInWithRedirect(this.auth, new GoogleAuthProvider());
    }
  }

  public isLoginWithLink() {
    return isSignInWithEmailLink(this.auth, window.location.href);
  }

  async logOut() {
    await this.reportSession();
    this.store.dispatch(new ResetAccountAction());
    await signOut(this.auth);
    await this.router.navigate(["login"]);
  }

  getToken() {
    return this.idToken$;
  }

  getAppUser() {
    return this.appUser;
  }

  public clearAppUser() {
    this.appUser = null;
  }

  async getRedirectResult() {
    try {
      const result = await getRedirectResult(this.auth);
      if (!result.user) {
        await this.router.navigate(["/login"]);
        return;
      }

      const email = result.user.email;
      const utm = this.getUTMParams();
      const lang = this.translate.currentLang || "en";
      const response: any = await this.http
        .post("/auth/sign-with-google", { email, lang, utm })
        .toPromise();

      if (!response.userExists) {
        this.toastr.info(
          this.translate.instant("login.userNotFound"),
          "",
          { timeOut: 3000 }
        );
        await signOut(this.auth);
        setTimeout(() => {
          window.location.href = "https://gsignature.com/sign-up";
        }, 3000);
        return;
      }
    } catch (error) {
      console.error("Google login validation error:", error);
      await signOut(this.auth);
      await this.router.navigate(["/login"]);
    }
  }

  getGeneratorToken(user: User): Observable<string> {
    const payload = {
      email: user.email,
      domain: this.currentDomain,
    };

    return this.http
      .post<{ token: string }>("/generator/generate-token", payload)
      .pipe(map((response) => response.token));
  }

  private storeUTMParams(): void {
    const params = new URLSearchParams(window.location.search);
    const utm: any = {};
    params.forEach((value, key) => {
      if (key.startsWith("utm_") || key === "gad_source" || key === "gclid") {
        utm[key] = value;
      }
    });
    if (Object.keys(utm).length > 0) {
      localStorage.setItem(UTM_KEY, JSON.stringify(utm));
    }
  }

  private getUTMParams(): any {
    const storedUTM = localStorage.getItem(UTM_KEY);
    if (storedUTM) {
      return JSON.parse(storedUTM);
    }
    const params = new URLSearchParams(window.location.search);
    const utm: any = {};
    params.forEach((value, key) => {
      if (key.startsWith("utm_") || key === "gad_source" || key === "gclid") {
        utm[key] = value;
      }
    });
    return utm;
  }

  public async reportLogin(): Promise<void> {
    if (this.loginReported || sessionStorage.getItem('login')) {
      return;
    }

    this.loginReported = true;

    const email = this.appUser?.email || this.authState?.email;
    const payload = {
      email: email,
      domain: this.currentDomain,
      sessionStart: new Date().toISOString()
    };

    try {
      await this.http.post('/user-activity/report/zapier', payload).toPromise();
      sessionStorage.setItem('login', 'true');
    } catch (error) {
      this.loginReported = false;
      throw error;
    }
  }

  fetchCurrent = () =>
    this.userService
      .current()
      .toPromise()
      .then((currentUser: User) => {
        this.store
          .select(getCurrentAccount)
          .pipe(take(1))
          .subscribe((currentAccount) => {
            if (
              !currentAccount ||
              !currentUser.availableDomains.includes(currentAccount)
            ) {
              this.store.dispatch(
                new ChangeAccountAction(currentUser.availableDomains[0])
              );
            }
          });

        this.appUser = currentUser;
        this.hasCompanyManagerAccess = [
          "SUPER_ADMIN",
          "COMPANY_MANAGER",
          "PARTNER",
        ].includes(currentUser.role);
        this.canChangeAccounts =
          currentUser.availableDomains.length > 1 ||
          currentUser.hasPartnerAccount;
        this.isSuperAdmin = currentUser.role === "SUPER_ADMIN";
        this.isCompanyManager = currentUser.role === "COMPANY_MANAGER";
        this.isPartner = currentUser.role === "PARTNER";
        this.trialFinishDate = currentUser.trialFinishDate;
        this.isGoogleAdmin = currentUser.isGoogleAdmin;
        this.lastGoogleSync = currentUser.lastGoogleSync;
        this.localData = currentUser.localData;

        this.reportLogin().catch();
      })
      .then(() =>
        this.appUser.planId || this.appUser.isPremium
          ? true
          : this.router.navigate(["/plan"])
      )
      .catch(() => this.logOut().then(() => false));

  public changeAvatarUrl = (avatarUrl) =>
    (this.appUser.thumbnailPhotoUrl = avatarUrl);
}

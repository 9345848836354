<h1 mat-dialog-title translate>installation-error.title</h1>

<button mat-icon-button mat-dialog-close class="close-button top-right">
  <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content class="dialog-content">
  <ul>
    <li *ngFor="let error of errors">
      <code>
        <ng-container *ngIf="error.isAlias; else notAlias">
          {{ 'employee.alias' | translate }}: {{ error.employeeEmail }}
        </ng-container>
        <ng-template #notAlias>
          {{ 'employee.employee' | translate }}: {{ error.employeeEmail }}
        </ng-template>
      </code>
      <span> ("{{ error.templateName }}")</span>
      <pre><code>{{ error.error }}</code></pre>
    </li>
  </ul>
</div>

<div mat-dialog-actions class="dialog-actions" align="end">
  <button mat-button mat-dialog-close>{{'close' | translate}}</button>
</div>

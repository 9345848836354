import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { validateNonRequiredPhoneNumber } from 'src/app/shared/validators/phone-number.validator';
import { GetEmployeeAction, UpdateEmployeeAction } from '../employees.actions';
import { getEmployeeSelector } from '../employees.reducer';
import { Employee } from '../employees.interface';
import { getEmployeeSettingsSelector } from '../../settings/settings.reducer';
import { EmployeeSetting } from '../../settings/settings.interface';
import { GetEmployeeSettingsAction } from '../../settings/settings.actions';
import { StartCheckingForInstallationInProgressAction } from '../../signature-installation-state/signature-installation-state.actions';
import { parseFooterTemplate } from '../../shared/utils/footers.util';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent implements OnInit {

  form: UntypedFormGroup;
  employee: Employee;
  employeeSettings: EmployeeSetting[];
  parsedSignature: string;
  previewType: 'external' | 'internal' = 'external';
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new GetEmployeeAction(this.route.snapshot.params.id));

    this.form = new UntypedFormGroup({
      excludedFromSignatures: new UntypedFormControl('', Validators.required),
      givenName: new UntypedFormControl({ value: '', disabled: true }),
      familyName: new UntypedFormControl({ value: '', disabled: true }),
      dynamicVariables: new FormGroup({}),
    });

    this.store.dispatch(new GetEmployeeSettingsAction());
    this.store.select(getEmployeeSettingsSelector).subscribe((employeeSettings: EmployeeSetting[]) => {
      this.employeeSettings = employeeSettings;
      employeeSettings.forEach((setting: EmployeeSetting) => {
        const validators = [];
        if (setting.mandatory) {
          validators.push(Validators.required);
        }
        if (['phoneNumber', 'mobileNumber'].includes(setting.name)) {
          validators.push(validateNonRequiredPhoneNumber);
        }
        this.dynamicVariables.addControl(
          setting.name,
          new FormControl({ value: setting.defaultValue, disabled: !setting.overridable }, validators)
        );
      });

      this.store.select(getEmployeeSelector).subscribe((employee: Employee) => {
        if (employee && this.route.snapshot.params.id === employee.id) {
          this.employee = employee;
          this.fillForm(employee);
          this.updateParsedSignature();
          this.isLoading = false;
        }
      });

    });
    this.form.updateValueAndValidity();
  }

  getCurrentSignature(): string {
    if (this.employee?.signature && this.employee?.signatureInternal) {
      return this.previewType === 'internal' ? this.employee.signatureInternal : this.employee.signature;
    } else if (this.employee?.signatureInternal) {
      return this.employee.signatureInternal;
    } else {
      return this.employee?.signature;
    }
  }

  updateParsedSignature(): void {
    if (this.employee) {
      console.log(this.employee);
      this.parsedSignature = parseFooterTemplate(this.getCurrentSignature(), this.employee);
    }
  }

  copySignatureToClipboard = () => {
    navigator.clipboard.writeText(this.parsedSignature);
    this.toastr.success(this.translate.instant('employee.copySignatureSuccess'));
  }

  get dynamicVariables() {
    return this.form.get('dynamicVariables') as FormGroup;
  }

  fillForm = (employee: Employee) => {
    this.form.patchValue({
      excludedFromSignatures: employee.excludedFromSignatures,
      givenName: employee.givenName,
      familyName: employee.familyName,
      dynamicVariables: employee.dynamicVariables
    });
    if (employee.signatureInternal && employee.signature) {
      this.previewType = 'external';
    } else if (employee.signatureInternal) {
      this.previewType = 'internal';
    } else {
      this.previewType = 'external';
    }
  }

  goToEmployeeList = () => this.router.navigate(['employees']);

  save = () => {
    if (this.form.valid) {
      this.store.dispatch(new UpdateEmployeeAction({ ...this.employee, ...this.form.value, installSignature: false }));
    } else {
      this.form.markAllAsTouched();
    }
  }

  saveAndInstall = () => {
    if (this.form.valid) {
      this.store.dispatch(new UpdateEmployeeAction({ ...this.employee, ...this.form.value, installSignature: true }));
      this.store.dispatch(new StartCheckingForInstallationInProgressAction());
    } else {
      this.form.markAllAsTouched();
    }
  }

  isPhoneNumberInput = name => ['phoneNumber', 'mobileNumber'].includes(name);

  downloadSignatureOutlook() {
    const htmlContent = this.parsedSignature;

    const [localPart, domain] = this.employee.email.split('@');

    const sanitizedLocalPart = localPart.replace(/\./g, '_');
    const sanitizedDomain = domain.replace(/\./g, '_');

    const fileName = `${sanitizedLocalPart}_${sanitizedDomain}.htm`;

    const blob = new Blob([htmlContent], { type: 'text/html' });

    saveAs(blob, fileName);
  }
}

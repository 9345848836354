<div class="integration-row">
  <div class="google-row">
    <div class="user-permissions-label">
      {{ 'settings.integration.google' | translate }}
    </div>

    <mat-divider class="user-permissions-divider"></mat-divider>

    <div *ngIf="lastGoogleSync" class="last-google-sync">
      <mat-icon>check</mat-icon>
      {{ 'settings.integration.lastGoogleSync' | translate: { lastGoogleSync: lastGoogleSyncFormatted } }}
    </div>

    <div class="integration-auth" *ngIf="environment.showFirebaseAuth">
      <p class="auth-description">
        {{ 'settings.integration.firebaseAuthDescription' | translate }}
      </p>
      <button mat-raised-button class="google-auth-button" (click)="authorizeFirebase()">
        <img src="assets/img/google.svg" alt="Google Logo" class="google-logo">
        <span>{{ 'settings.integration.authorizeButton' | translate }}</span>
      </button>
    </div>

    <div class="delegation-row">
      <p>
        {{ 'settings.integration.description' | translate }}
        <a href="https://admin.google.com/ac/owl/domainwidedelegation" target="_blank">(link)</a>
      </p>

      <ol>
        <li>
          {{ 'settings.integration.instruction1' | translate }}
          <a href="https://admin.google.com/ac/owl/domainwidedelegation" target="_blank">(link)</a>
        </li>
        <li>
          {{ 'settings.integration.instruction2' | translate }}
          <a [routerLink]="['/employees']">(link)</a>
        </li>
        <li>
          {{ 'settings.integration.instruction3' | translate }}
          <a [routerLink]="['/settings', 'advanced']">(link)</a>
        </li>
      </ol>

      <div class="copy-box">
        <span class="coby-box-header">{{ 'settings.integration.clientId' | translate }}</span>
        <mat-icon (click)="copyToClipboard(clientId)">content_copy</mat-icon>
        <code>{{ clientId }}</code>
      </div>

      <div class="copy-box">
        <span class="coby-box-header">{{ 'settings.integration.scopes' | translate }}</span>
        <mat-icon (click)="copyToClipboard(scopes)">content_copy</mat-icon>
        <code>{{ scopes }}</code>
      </div>
    </div>
  </div>

  <div class="proxy-row">
    <div class="user-permissions-label">
      {{ 'settings.integration.proxy' | translate }}
    </div>

    <mat-divider class="user-permissions-divider"></mat-divider>

    <mat-slide-toggle class="proxy-toogle" [(ngModel)]="proxyEnabled" (change)="toggleProxy($event.checked)">
      {{ 'settings.integration.enableProxy' | translate }}
    </mat-slide-toggle>

    <div *ngIf="proxyEnabled" class="internal-signature-row">
      <mat-checkbox
        [(ngModel)]="internalSignatureEnabled"
        (change)="toggleInternalSignature($event.checked)"
        [matTooltip]="'settings.integration.internalSignatureTooltip' | translate"
        matTooltipPosition="above">
        {{ 'settings.integration.enableInternalSignature' | translate }}
      </mat-checkbox>
    </div>    

    <div class="integration-row proxy-instruction" *ngIf="proxyEnabled">
      <h3>{{ 'settings.integration.proxyConfigurationTitle' | translate }}</h3>
      <p class="instruction-description">
        {{ 'settings.integration.proxyConfigurationDescription' | translate }}
      </p>

      <mat-accordion>

        <mat-expansion-panel [class.completed-step]="step1Completed">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.integration.proxyConfigurationStep1Title' | translate }}
            </mat-panel-title>
            <mat-checkbox color="primary" [(ngModel)]="step1Completed" (change)="onStepChange()"></mat-checkbox>
          </mat-expansion-panel-header>

          <p>
            {{ 'settings.integration.proxyConfigurationStep1Description' | translate }}
            <a href="https://admin.google.com/ac/apps/gmail/hosts" target="_blank">
              {{ 'settings.integration.proxyConfigurationStep1LinkText' | translate }}
            </a>
          </p>
          <ul>
            <li>{{ 'settings.integration.proxyConfigurationStep1Bullet1' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep1Bullet2' | translate }}</li>
            <li>
              {{ 'settings.integration.proxyConfigurationStep1Bullet3' | translate }}
              <div class="copy-container">
                <div class="copy-box">
                  <span class="coby-box-header">IP:</span>
                  <mat-icon (click)="copyToClipboard(proxyDNS)">content_copy</mat-icon>
                  <code>{{ proxyDNS }}</code>
                </div>
                <div class="copy-box">
                  <span class="coby-box-header">Port:</span>
                  <mat-icon (click)="copyToClipboard(proxyPort)">content_copy</mat-icon>
                  <code>{{ proxyPort }}</code>
                </div>
              </div>
            </li>
            <li>{{ 'settings.integration.proxyConfigurationStep1Bullet4' | translate }}</li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel [class.completed-step]="step2Completed">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.integration.proxyConfigurationStep2Title' | translate }}
            </mat-panel-title>
            <mat-checkbox color="primary" [(ngModel)]="step2Completed" (change)="onStepChange()"></mat-checkbox>
          </mat-expansion-panel-header>

          <p>
            {{ 'settings.integration.proxyConfigurationStep2Description' | translate }}
            <a href="https://admin.google.com/ac/apps/gmail/compliance" target="_blank">
              {{ 'settings.integration.proxyConfigurationStep2LinkText' | translate }}
            </a>
          </p>
          <ul>
            <li>{{ 'settings.integration.proxyConfigurationStep2Bullet1' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep2Bullet2' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep2Bullet3' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep2Bullet4' | translate }}</li>
            <li>
              {{ 'settings.integration.proxyConfigurationStep2Bullet5' | translate }}
              <ul>
                <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub1' | translate }}
                  <ul>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub1Sub1' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub1Sub2' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub1Sub3' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub1Sub4' | translate }}
                      <div class="copy-box">
                        <span class="coby-box-header">{{ 'settings.integration.proxySenderHeader' | translate }}</span>
                        <mat-icon (click)="copyToClipboard(xGSignature)">content_copy</mat-icon>
                        <code>{{ xGSignature }}</code>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub2' | translate }}
                  <ul>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub2Sub1' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub2Sub2' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub2Sub3' | translate }}</li>
                    <li>{{ 'settings.integration.proxyConfigurationStep2Bullet5Sub2Sub4' | translate }}
                      <div class="copy-box">
                        <span class="coby-box-header">{{ 'settings.integration.proxySenderHeader' | translate }}</span>
                        <mat-icon (click)="copyToClipboard(fromSenderRegex)">content_copy</mat-icon>
                        <code>{{ fromSenderRegex }}</code>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>{{ 'settings.integration.proxyConfigurationStep2Bullet6' | translate }}</li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel [class.completed-step]="step3Completed">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.integration.proxyConfigurationStep3Title' | translate }}
            </mat-panel-title>
            <mat-checkbox color="primary" [(ngModel)]="step3Completed" (change)="onStepChange()"></mat-checkbox>
          </mat-expansion-panel-header>

          <p>
            {{ 'settings.integration.proxyConfigurationStep3Description' | translate }}
            <a href="https://admin.google.com/ac/apps/gmail/routing" target="_blank">
              {{ 'settings.integration.proxyConfigurationStep3LinkText' | translate }}
            </a>
          </p>
          <ul>
            <li>{{ 'settings.integration.proxyConfigurationStep3Bullet1' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep3Bullet2' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep3Bullet3' | translate }}</li>
            <li>{{ 'settings.integration.proxyConfigurationStep3Bullet4' | translate }}</li>
            <li>
              {{ 'settings.integration.proxyConfigurationStep3Bullet5' | translate }}
              <div class="copy-box">
                <span class="coby-box-header">IP:</span>
                <mat-icon (click)="copyToClipboard(proxyHostIp)">content_copy</mat-icon>
                <code>{{ proxyHostIp }}</code>
              </div>
            </li>
          </ul>
        </mat-expansion-panel>

        <mat-expansion-panel [class.completed-step]="step4Completed" [class.invalid-step]="spfCheckResult === false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'settings.integration.proxyConfigurationStep4Title' | translate }}
            </mat-panel-title>
            <mat-checkbox color="primary" [(ngModel)]="step4Completed" (click)="$event.preventDefault()"
              [disabled]="true">
            </mat-checkbox>
          </mat-expansion-panel-header>

          <p>
            {{ 'settings.integration.proxyConfigurationStep4Description' | translate }}
            <a href="https://support.google.com/a/answer/33786" target="_blank">(link)</a>
          </p>

          <div class="spf-highlight">
            <p>
              {{ 'settings.integration.proxyConfigurationStep4Example' | translate }}
            </p>
            <code>{{ proxySPF }}</code>
          </div>

          <button mat-raised-button color="primary" (click)="verifySpfRecord()">
            {{ 'settings.integration.checkSpfButton' | translate }}
          </button>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- <mat-card-content class="disclaimer-card">
        <mat-icon>error_outline</mat-icon>
        <span class="disclaimer-text">
          {{ 'settings.integration.proxyDisclaimer' | translate }}
        </span>
      </mat-card-content> -->
    </div>
  </div>

  <div class="integration-row action-wrapper">
    <button mat-raised-button color="primary" (click)="saveIntegrationSettings()"
      [disabled]="savingProxy || !isAllStepsCompleted" [class.loading-button]="savingProxy">
      <mat-spinner diameter="20" *ngIf="savingProxy"></mat-spinner>
      <span *ngIf="!savingProxy">
        {{ 'save' | translate }}
      </span>
    </button>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-handwritten-signature',
  templateUrl: './handwritten-signature.component.html',
  styleUrls: ['./handwritten-signature.component.scss']
})
export class HandwrittenSignatureComponent implements OnInit {
  readonly FILE_UPLOAD_LABEL = 'file_upload';
  @Input() form: FormGroup;

  fileName = '';
  useHandwrittenSignature = false;
  selectedStyle: any = null;
  generatedPreviews: string[] = [];
  uploadedImage: string | null = null;

  signatureStyles = [
    { label: '1', font: 'Alex Brush', size: 28, color: '#000000' },
    { label: '2', font: 'Bad Script', size: 28, color: '#000000' },
    { label: '3', font: 'Birthstone', size: 28, color: '#000000' },
    { label: '4', font: 'Allura', size: 28, color: '#000000' },
    { label: '5', font: 'Dancing Script', size: 28, color: '#000000' },
    { label: '6', font: 'Great Vibes', size: 28, color: '#000000' },
    { label: '7', font: 'La Belle Aurore', size: 28, color: '#000000' },
    { label: '8', font: 'Marck Script', size: 28, color: '#000000' },
    { label: '9', font: 'MonteCarlo', size: 28, color: '#000000' },
    { label: '10', font: 'Over the Rainbow', size: 28, color: '#000000' },
    { label: '11', font: 'Parisienne', size: 28, color: '#000000' },
    { label: '12', font: 'Italianno', size: 28, color: '#000000' },
  ];

  constructor() {}

  ngOnInit(): void {
    const arr = this.form.get('handwrittenSignature') as FormArray;
    if (arr && arr.length > 0) {
      const item = arr.at(0).value;
      if (item && (item.url || item.inputText)) {
        this.useHandwrittenSignature = true;
      }
  
      if (item.label === this.FILE_UPLOAD_LABEL && item.url) {
        this.uploadedImage = item.url;
      } else if (item.label && item.label.trim() !== '') {
        this.selectedStyle = this.signatureStyles.find(style => style.label === item.label) || null;
      }
    }
  
    this.generateSignaturePreview();
  
    this.form.get('signatureNameInput')?.valueChanges.subscribe(value => {
      this.generateSignaturePreview();
    });
  }

  get firstHandwrittenSignatureFg(): FormGroup {
    const arr = this.form.get('handwrittenSignature') as FormArray;
    return arr && arr.length > 0 ? (arr.at(0) as FormGroup) : null;
  }

  generateSignaturePreview(): void {
    const text = this.form.get('signatureNameInput')?.value?.trim() || 'John Williams';
    Promise.all(this.signatureStyles.map(style =>
      this.generatePreviewForStyle(text, style)
    )).then(previews => {
      this.generatedPreviews = previews;
    });
  }

  toggleHandwrittenSignature(checked: boolean): void {
    this.useHandwrittenSignature = checked;
  }

  onHandwrittenSignatureFileSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const file = element.files?.[0];
    if (!file) {
      return;
    }
    this.fileName = file.name;
  
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64Image = e.target.result;
      if (this.firstHandwrittenSignatureFg) {
        const inputText = this.form.get('signatureNameInput')?.value || '';
        this.firstHandwrittenSignatureFg.patchValue({
          label: this.FILE_UPLOAD_LABEL,
          input: inputText,
          url: base64Image
        });
      }
      this.useHandwrittenSignature = true;
  
      this.uploadedImage = base64Image;
    };
    reader.readAsDataURL(file);
  }

  onStyleSelected(style: any, index: number): void {
    this.selectedStyle = style;
    if (this.generatedPreviews[index] && this.firstHandwrittenSignatureFg) {
      const inputText = this.form.get('signatureNameInput')?.value || '';
      this.firstHandwrittenSignatureFg.patchValue({
        label: style.label,
        input: inputText,
        url: this.generatedPreviews[index]
      });
    }
    this.uploadedImage = null;
  }

  async generatePreviewForStyle(text: string, style: any): Promise<string> {
    const font = `${style.size}px "${style.font}"`;
    await document.fonts.load(font);
  
    const previewDiv = document.createElement('div');
    previewDiv.style.position = 'absolute';
    previewDiv.style.visibility = 'hidden';
    previewDiv.style.whiteSpace = 'nowrap';
    previewDiv.style.fontFamily = style.font;
    previewDiv.style.fontSize = `${style.size}px`;
    previewDiv.style.lineHeight = '1.2';
    previewDiv.textContent = text;
  
    document.body.appendChild(previewDiv);
    const textWidth = previewDiv.offsetWidth;
    const textHeight = previewDiv.offsetHeight;
    document.body.removeChild(previewDiv);
  
    const canvas = document.createElement('canvas');
    canvas.width = textWidth + 20;
    canvas.height = textHeight + 5;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Canvas not supported');
      return '';
    }
  
    ctx.font = font;
    ctx.fillStyle = style.color;
    ctx.textBaseline = 'top';
    ctx.fillText(text, 10, 10);
  
    return canvas.toDataURL('image/png');
  }
}

import { createSelector } from '@ngrx/store';
import {
  EmployeesActions,
  GET_CURRENT_USER_SUCCESS,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  SET_EMPLOYEES_PARAMS,
  REMOVE_SIGNATURE_TEMPLATE_SUCCESS,
  SYNC_GOOGLE_EMPLOYEES,
  SYNC_GOOGLE_EMPLOYEES_SUCCESS,
  SYNC_GOOGLE_EMPLOYEES_FAILURE,
} from './employees.actions';
import { EmployeesState, defaultEmployeeListPager } from './employees.interface';

export const initialState: EmployeesState = {
  employees: {
    total: 0,
    data: []
  },
  params: {
    sortBy: 'fullName',
    pager: defaultEmployeeListPager,
    viewType: 'EMPLOYEES'
  },
  currentEmployee: null,
  employee: null,
  isSyncInProgress: false
};

export function employeesReducer(
  state: EmployeesState = initialState,
  action: EmployeesActions
): EmployeesState {
  switch (action.type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload
      };
    case SET_EMPLOYEES_PARAMS:
      return {
        ...state,
        params: action.payload
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentEmployee: action.payload
      };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employee: action.payload
      };
    case REMOVE_SIGNATURE_TEMPLATE_SUCCESS:
      return {
        ...state,
        employees: {
          ...state.employees,
          data: state.employees.data.map(emp => {
            if (emp.id !== action.payload.id) return emp;
            if (action.payload.internal) {
              return { ...emp, signatureInternalTemplateId: null };
            } else {
              return { ...emp, signatureTemplateId: null };
            }
          })
        }
      };
    case SYNC_GOOGLE_EMPLOYEES:
      return {
        ...state,
        isSyncInProgress: true
      };
    case SYNC_GOOGLE_EMPLOYEES_SUCCESS:
    case SYNC_GOOGLE_EMPLOYEES_FAILURE:
      return {
        ...state,
        isSyncInProgress: false
      };
    default: {
      return state;
    }
  }
}

export const getEmployeesState = state => state.employees;
export const getCurrentEmployeeState = state => state.currentEmployee;
export const getEmployeeState = state => state.employee;
export const getIsSyncInProgressState = state => state.isSyncInProgress;
export const getWholeEmployeeListState = (employeeListState: EmployeesState): EmployeesState => employeeListState;

export const getEmployeeListSelector = createSelector(getWholeEmployeeListState, getEmployeesState);
export const getCurrentEmployeeSelector = createSelector(getEmployeesState, getCurrentEmployeeState);
export const getEmployeeSelector = createSelector(getEmployeesState, getEmployeeState);
export const isSyncInProgressSelector = createSelector(getEmployeesState, getIsSyncInProgressState);
export const getEmployeesParamsSelector = createSelector(getEmployeesState, (state: EmployeesState) => state.params);

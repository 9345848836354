<mat-toolbar class="header">
  <span>{{footer?.name}}</span>
</mat-toolbar>

<app-loading *ngIf="!footer"></app-loading>

<form [formGroup]="form" class="form" (ngSubmit)="save()" *ngIf="footer">

  <mat-form-field appearance="fill" class="name-form-field">
    <mat-label>{{'footers.name' | translate}}</mat-label>
    <input matInput formControlName="name" type="text">
    <mat-error *ngIf="form.hasError('required', ['name'])">{{'fieldRequired' | translate}}</mat-error>
  </mat-form-field>

  <div class="editor-container">
    <div class="editor-display editor-preview">
      <h2>{{'footers.preview' | translate}}</h2>
      <div class="preview" [innerHTML]="sanitizedContent"></div>
    </div>
    
    <div class="editor-display code-editor">
      <mat-tab-group [(selectedIndex)]="activeEditorTab">
        <mat-tab label="{{'footers.textEditor' | translate}}">
          <angular-editor
            matInput
            formControlName="content"
            [config]="config">
          </angular-editor>
        </mat-tab>
        <mat-tab label="{{'footers.htmlEditor' | translate}}">
          <ngx-codemirror
            formControlName="content"
            [options]="codeEditorOptions">
          </ngx-codemirror>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="dynamic-menu">
    <div class="menu-buttons">
      <button mat-raised-button type="button" (click)="toggleSection('addons')">
        <mat-icon>add_box</mat-icon> {{ 'footers.addons' | translate }}
      </button>
      <button mat-raised-button type="button" (click)="toggleSection('dynamicVariables')">
        <mat-icon>tune</mat-icon> {{ 'footers.dynamicVariables' | translate }}
      </button>
      <mat-slide-toggle 
        formControlName="trialSignature"
        matTooltip="{{ isTrialSignature ? ('footers.trialSignatureDisabled' | translate) : '' }}"
        [matTooltipDisabled]="!isTrialSignature"
      >
        {{ 'footers.trialSignature' | translate }}
      </mat-slide-toggle>   
    </div>
  
    <div class="menu-content">
      <div class="section" [class.hidden]="activeSection !== 'addons'">
        <app-addons *ngIf="addonsLoaded" [form]="form"></app-addons>
      </div>

      <div class="section" [class.hidden]="activeSection !== 'dynamicVariables'">
        <div class="dynamic">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'footers.userAvatarWidth' | translate }}</mat-label>
            <input matInput formControlName="userAvatarWidth" type="number" min="0">
            <span matSuffix>px</span>
          </mat-form-field>
    
          <mat-form-field appearance="fill">
            <mat-label>{{ 'footers.userAvatarBorderRadius' | translate }}</mat-label>
            <input matInput formControlName="userAvatarBorderRadius" type="number" min="0" max="50">
            <span matSuffix>%</span>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'footers.phoneLabel' | translate }}</mat-label>
            <input matInput formControlName="phoneLabel" type="text">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'footers.mobileLabel' | translate }}</mat-label>
            <input matInput formControlName="mobileLabel" type="text">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'footers.emailLabel' | translate }}</mat-label>
            <input matInput formControlName="emailLabel" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <button mat-raised-button (click)="goToSignatures()">{{'cancel' | translate}}</button>
    <button mat-stroked-button color="primary" (click)="saveAndInstall()"
            [disabled]="!form.valid || updating">{{(updating ? 'saving' : 'saveAndInstall') | translate}}
    </button>
    <button mat-raised-button type="submit" color="primary"
            [disabled]="!form.valid || updating">{{(updating ? 'saving' : 'save') | translate}}
    </button>
  </div>

  <app-placeholders></app-placeholders>
</form>

import { Component, OnInit, OnDestroy } from "@angular/core";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store, ActionsSubject } from "@ngrx/store";
import { ofType } from "@ngrx/effects";
import { ToastrTranslatedService } from "../shared/toastr-translated.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../auth/auth.service";
import { MY_FORMATS } from "../shared/shared.module";
import {
  GetIntegrationSettingsAction,
  ChangeIntegrationSettingsAction,
  CHANGE_INTEGRATION_SETTINGS_SUCCESS,
  CHANGE_INTEGRATION_SETTINGS_FAILURE,
} from "../settings/settings.actions";
import { IntegrationSettings } from "../settings/settings.interface";
import { getSettingsSelector } from "../settings/settings.reducer";
import { IntegrationSettingsService } from "./integration-settings.service";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import { environment } from "../../environments/environment";

initializeApp(environment.firebaseConfig);

@Component({
  selector: "app-integration-settings",
  templateUrl: "./integration-settings.component.html",
  styleUrls: ["./integration-settings.component.scss"],
})
export class IntegrationSettingsComponent implements OnInit, OnDestroy {
  public environment = environment;

  public isSuperAdmin: boolean;

  public readonly clientId = "111875350985434773237";
  public readonly scopes =
    "https://www.googleapis.com/auth/gmail.settings.sharing, https://www.googleapis.com/auth/gmail.settings.basic, https://www.googleapis.com/auth/admin.directory.user.readonly, https://www.googleapis.com/auth/admin.directory.orgunit.readonly, https://www.googleapis.com/auth/admin.directory.group.readonly, https://www.googleapis.com/auth/calendar.readonly";
  public readonly redirectUri =
    "https://gstamp.firebaseapp.com/__/auth/handler";

  public lastGoogleSync: Date | undefined;
  public lastGoogleSyncFormatted: string | undefined;

  public proxyEnabled = false;
  public internalSignatureEnabled = false;
  private currentSettings: IntegrationSettings | null = null;

  public savingProxy = false;
  private destroy$ = new Subject<void>();

  public readonly proxyDNS = "mailserver.gsignature.com";
  public readonly proxyHostIp = "34.159.124.230";
  public readonly proxyPort = "587";
  public readonly xGSignature = "X-gSignature-MessageProcessed: Yes";
  public readonly fromSenderRegex =
    "(From:.*|Sender:.*)?(drive-shares-dm-noreply@google.com|drive-shares-noreply@google.com|calendar-notification@google.com)";
  public readonly proxySPF = "v=spf1 include:_spf.google.com ~all";

  public step1Completed = false;
  public step2Completed = false;
  public step3Completed = false;
  public step4Completed = false;
  public spfCheckResult: boolean | null = null;

  constructor(
    private readonly toastr: ToastrTranslatedService,
    private readonly translate: TranslateService,
    private readonly auth: AuthService,
    private readonly store: Store<any>,
    private readonly actions$: ActionsSubject,
    private readonly integrationSettingsService: IntegrationSettingsService
  ) {
    this.lastGoogleSync = this.auth.appUser.lastGoogleSync;
    if (this.lastGoogleSync) {
      this.lastGoogleSyncFormatted = moment(this.lastGoogleSync).format(
        MY_FORMATS.display.dateInput
      );
    }
    this.isSuperAdmin = this.auth.isSuperAdmin;

    this.actions$
      .pipe(
        ofType(
          CHANGE_INTEGRATION_SETTINGS_SUCCESS,
          CHANGE_INTEGRATION_SETTINGS_FAILURE
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.savingProxy = false;
      });
  }

  ngOnInit(): void {
    this.store.dispatch(new GetIntegrationSettingsAction());
    this.store
      .select(getSettingsSelector)
      .subscribe((settings: IntegrationSettings | null) => {
        if (!settings) return;
        this.currentSettings = settings;
        this.proxyEnabled = !!settings.proxy;
        this.internalSignatureEnabled = settings.internalSignature ?? false;
        if (this.proxyEnabled) {
          this.step1Completed = true;
          this.step2Completed = true;
          this.step3Completed = true;
          this.step4Completed = true;
          this.spfCheckResult = true;
        }
      });

    const auth = getAuth();
    getRedirectResult(auth)
      .then((result) => {
        if (result && result.user) {
          const credential = GoogleAuthProvider.credentialFromResult(result);
        }
      })
      .catch((error) => {
        console.error("Error handling Firebase redirect:", error);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleProxy(checked: boolean): void {
    this.proxyEnabled = checked;
    if (checked) {
      this.step1Completed = false;
      this.step2Completed = false;
      this.step3Completed = false;
      this.step4Completed = false;
      this.spfCheckResult = null;
    }
  }

  toggleInternalSignature(checked: boolean): void {
    this.internalSignatureEnabled = checked;
  }

  copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    this.toastr.success("settings.integration.copied");
  };

  public onStepChange(): void { }

  public get backendProxyEnabled(): boolean {
    return this.currentSettings ? this.currentSettings.proxy : false;
  }

  public verifySpfRecord(): void {
    this.spfCheckResult = null;
    this.integrationSettingsService.checkSpfRecord().subscribe({
      next: (res) => {
        this.spfCheckResult = res.valid;
        this.step4Completed = res.valid;
        if (res.valid) {
          this.toastr.success(
            this.translate.instant("settings.integration.spfValid")
          );
        } else {
          this.toastr.error(
            this.translate.instant("settings.integration.spfInvalid")
          );
        }
      },
      error: (err) => {
        console.error("Error checking SPF record:", err);
        this.spfCheckResult = false;
        this.step4Completed = false;
        this.toastr.error(
          this.translate.instant("settings.integration.spfError")
        );
      },
    });
  }

  get isAllStepsCompleted(): boolean {
    return (
      !this.proxyEnabled ||
      (this.step1Completed &&
        this.step2Completed &&
        this.step3Completed &&
        this.step4Completed)
    );
  }

  public saveIntegrationSettings(): void {
    if (!this.currentSettings) {
      this.toastr.error("settings.integration.currentSettings");
      return;
    }
    this.savingProxy = true;
    const updatedSettings: IntegrationSettings = {
      ...this.currentSettings,
      proxy: this.proxyEnabled,
      internalSignature: this.internalSignatureEnabled
    };
    this.store.dispatch(new ChangeIntegrationSettingsAction(updatedSettings));
  }

  public authorizeFirebase(): void {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    this.scopes
      .split(",")
      .map((scope) => scope.trim())
      .forEach((scope) => {
        provider.addScope(scope);
      });
    signInWithRedirect(auth, provider);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SharedModule} from '../shared/shared.module';
import {SettingsComponent} from './settings.component';
import {EmployeeSettingsComponent} from '../employee-settings/employee-settings.component';
import {SettingsEffects} from './settings.effects';
import {SettingsService} from './settings.service';
import {settingsReducer} from './settings.reducer';
import {CompanyComponent} from '../company/company.component';
import {AdvancedSettingsComponent} from '../advanced-settings/advanced-settings.component';
import {BillingComponent} from '../billing/billing.component';
import {BillingSettingsComponent} from '../billing/settings/billing-settings.component';
import {BillingSuccessComponent} from '../billing/success/success.component';
import {BillingCancelComponent} from '../billing/cancel/cancel.component';
import {ApiSettingsComponent} from '../api-settings/api-settings.component';
import {CreateTokenDialogComponent} from '../api-settings/create-token/create-token-dialog.component';
import {IntegrationSettingsComponent} from '../integration-settings/integration-settings.component';
import {FormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    CompanyComponent,
    EmployeeSettingsComponent,
    AdvancedSettingsComponent,
    SettingsComponent,
    BillingComponent,
    BillingSuccessComponent,
    BillingCancelComponent,
    IntegrationSettingsComponent,
    BillingSettingsComponent,
    ApiSettingsComponent,
    CreateTokenDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forFeature('settings', settingsReducer),
    EffectsModule.forFeature([SettingsEffects]),
    RouterModule,
    FormsModule,
    MatSlideToggleModule
  ],
  providers: [SettingsService]
})
export class SettingsModule {
}

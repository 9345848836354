import { Action } from '@ngrx/store';
import {
  AdvancedSettings,
  BillingSettings,
  CompanySettings,
  EmployeeSetting,
  PredefinedEmployeeSetting,
  IntegrationSettings
} from './settings.interface';

export const GET_EMPLOYEE_SETTINGS = 'GET_EMPLOYEE_SETTINGS';
export const GET_EMPLOYEE_SETTINGS_SUCCESS = 'GET_EMPLOYEE_SETTINGS_SUCCESS';
export const GET_EMPLOYEE_SETTINGS_FAILURE = 'GET_EMPLOYEE_SETTINGS_FAILURE';

export const CHANGE_EMPLOYEE_SETTINGS_SUCCESS = 'CHANGE_EMPLOYEE_SETTINGS_SUCCESS';
export const CHANGE_EMPLOYEE_SETTINGS_FAILURE = 'CHANGE_EMPLOYEE_SETTINGS_FAILURE';
export const CHANGE_EMPLOYEE_SETTINGS = 'CHANGE_EMPLOYEE_SETTINGS';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const GET_COMPANY_SETTINGS_SUCCESS = 'GET_COMPANY_SETTINGS_SUCCESS';
export const GET_COMPANY_SETTINGS_FAILURE = 'GET_COMPANY_SETTINGS_FAILURE';

export const CHANGE_COMPANY_SETTINGS_SUCCESS = 'CHANGE_COMPANY_SETTINGS_SUCCESS';
export const CHANGE_COMPANY_SETTINGS_FAILURE = 'CHANGE_COMPANY_SETTINGS_FAILURE';
export const CHANGE_COMPANY_SETTINGS = 'CHANGE_COMPANY_SETTINGS';

export const GET_PREDEFINED_EMPLOYEE_SETTINGS = 'GET_PREDEFINED_EMPLOYEE_SETTINGS';
export const GET_PREDEFINED_EMPLOYEE_SETTINGS_SUCCESS = 'GET_PREDEFINED_EMPLOYEE_SETTINGS_SUCCESS';
export const GET_PREDEFINED_EMPLOYEE_SETTINGS_FAILURE = 'GET_PREDEFINED_EMPLOYEE_SETTINGS_FAILURE';

export const GET_ADVANCED_SETTINGS = 'GET_ADVANCED_SETTINGS';
export const GET_ADVANCED_SETTINGS_SUCCESS = 'GET_ADVANCED_SETTINGS_SUCCESS';
export const GET_ADVANCED_SETTINGS_FAILURE = 'GET_ADVANCED_SETTINGS_FAILURE';

export const CHANGE_ADVANCED_SETTINGS_SUCCESS = 'CHANGE_ADVANCED_SETTINGS_SUCCESS';
export const CHANGE_ADVANCED_SETTINGS_FAILURE = 'CHANGE_ADVANCED_SETTINGS_FAILURE';
export const CHANGE_ADVANCED_SETTINGS = 'CHANGE_ADVANCED_SETTINGS';

export const GET_BILLING_SETTINGS = 'GET_BILLING_SETTINGS';
export const GET_BILLING_SETTINGS_SUCCESS = 'GET_BILLING_SETTINGS_SUCCESS';
export const GET_BILLING_SETTINGS_FAILURE = 'GET_BILLING_SETTINGS_FAILURE';

export const CHANGE_BILLING_SETTINGS = 'CHANGE_BILLING_SETTINGS';
export const CHANGE_BILLING_SETTINGS_SUCCESS = 'CHANGE_BILLING_SETTINGS_SUCCESS';
export const CHANGE_BILLING_SETTINGS_FAILURE = 'CHANGE_BILLING_SETTINGS_FAILURE';

export const GET_INTEGRATION_SETTINGS = 'GET_INTEGRATION_SETTINGS';
export const GET_INTEGRATION_SETTINGS_SUCCESS = 'GET_INTEGRATION_SETTINGS_SUCCESS';
export const GET_INTEGRATION_SETTINGS_FAILURE = 'GET_INTEGRATION_SETTINGS_FAILURE';

export const CHANGE_INTEGRATION_SETTINGS_SUCCESS = 'CHANGE_INTEGRATION_SETTINGS_SUCCESS';
export const CHANGE_INTEGRATION_SETTINGS_FAILURE = 'CHANGE_INTEGRATION_SETTINGS_FAILURE';
export const CHANGE_INTEGRATION_SETTINGS = 'CHANGE_INTEGRATION_SETTINGS';

export class GetEmployeeSettingsAction implements Action {
  readonly type = GET_EMPLOYEE_SETTINGS;

  constructor() {}
}

export class GetEmployeeSettingsSuccessAction implements Action {
  readonly type = GET_EMPLOYEE_SETTINGS_SUCCESS;
  constructor(public payload: EmployeeSetting[]) {}
}

export class GetEmployeeSettingsFailureAction implements Action {
  readonly type = GET_EMPLOYEE_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeEmployeeSettingsAction implements Action {
  readonly type = CHANGE_EMPLOYEE_SETTINGS;
  constructor(public payload: EmployeeSetting[]) {}
}

export class ChangeEmployeeSettingsSuccessAction implements Action {
  readonly type = CHANGE_EMPLOYEE_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeEmployeeSettingsFailureAction implements Action {
  readonly type = CHANGE_EMPLOYEE_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class GetCompanySettingsAction implements Action {
  readonly type = GET_COMPANY_SETTINGS;
  constructor() {}
}

export class GetCompanySettingsSuccessAction implements Action {
  readonly type = GET_COMPANY_SETTINGS_SUCCESS;
  constructor(public payload: CompanySettings) {}
}

export class GetCompanySettingsFailureAction implements Action {
  readonly type = GET_COMPANY_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeCompanySettingsAction implements Action {
  readonly type = CHANGE_COMPANY_SETTINGS;
  constructor(public payload: CompanySettings) {}
}

export class ChangeCompanySettingsSuccessAction implements Action {
  readonly type = CHANGE_COMPANY_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeCompanySettingsFailureAction implements Action {
  readonly type = CHANGE_COMPANY_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class GetPredefinedEmployeeSettingsAction implements Action {
  readonly type = GET_PREDEFINED_EMPLOYEE_SETTINGS;
  constructor() {}
}

export class GetPredefinedEmployeeSettingsSuccessAction implements Action {
  readonly type = GET_PREDEFINED_EMPLOYEE_SETTINGS_SUCCESS;
  constructor(public payload: PredefinedEmployeeSetting[]) {}
}

export class GetPredefinedEmployeeSettingsFailureAction implements Action {
  readonly type = GET_PREDEFINED_EMPLOYEE_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class GetAdvancedSettingsAction implements Action {
  readonly type = GET_ADVANCED_SETTINGS;
  constructor() {}
}

export class GetAdvancedSettingsSuccessAction implements Action {
  readonly type = GET_ADVANCED_SETTINGS_SUCCESS;
  constructor(public payload: AdvancedSettings) {}
}

export class GetAdvancedSettingsFailureAction implements Action {
  readonly type = GET_ADVANCED_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeAdvancedSettingsAction implements Action {
  readonly type = CHANGE_ADVANCED_SETTINGS;
  constructor(public payload: AdvancedSettings) {}
}

export class ChangeAdvancedSettingsSuccessAction implements Action {
  readonly type = CHANGE_ADVANCED_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeAdvancedSettingsFailureAction implements Action {
  readonly type = CHANGE_ADVANCED_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class GetBillingSettingsAction implements Action {
  readonly type = GET_BILLING_SETTINGS;
  constructor() {}
}

export class GetBillingSettingsSuccessAction implements Action {
  readonly type = GET_BILLING_SETTINGS_SUCCESS;
  constructor(public payload: BillingSettings) {}
}

export class GetBillingSettingsFailureAction implements Action {
  readonly type = GET_BILLING_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeBillingSettingsAction implements Action {
  readonly type = CHANGE_BILLING_SETTINGS;
  constructor(public payload: BillingSettings) {}
}

export class ChangeBillingSettingsSuccessAction implements Action {
  readonly type = CHANGE_BILLING_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeBillingSettingsFailureAction implements Action {
  readonly type = CHANGE_BILLING_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class GetIntegrationSettingsAction implements Action {
  readonly type = GET_INTEGRATION_SETTINGS;
  constructor() {}
}

export class GetIntegrationSettingsSuccessAction implements Action {
  readonly type = GET_INTEGRATION_SETTINGS_SUCCESS;
  constructor(public payload: IntegrationSettings) {}
}

export class GetIntegrationSettingsFailureAction implements Action {
  readonly type = GET_INTEGRATION_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export class ChangeIntegrationSettingsAction implements Action {
  readonly type = CHANGE_INTEGRATION_SETTINGS;
  constructor(public payload: IntegrationSettings) {}
}

export class ChangeIntegrationSettingsSuccessAction implements Action {
  readonly type = CHANGE_INTEGRATION_SETTINGS_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeIntegrationSettingsFailureAction implements Action {
  readonly type = CHANGE_INTEGRATION_SETTINGS_FAILURE;
  constructor(public payload: any) {}
}

export type SettingsActions =
  | GetEmployeeSettingsAction
  | GetEmployeeSettingsSuccessAction
  | GetEmployeeSettingsFailureAction
  | ChangeEmployeeSettingsAction
  | ChangeEmployeeSettingsSuccessAction
  | ChangeEmployeeSettingsFailureAction
  | GetCompanySettingsAction
  | GetCompanySettingsSuccessAction
  | GetCompanySettingsFailureAction
  | ChangeCompanySettingsAction
  | ChangeCompanySettingsSuccessAction
  | ChangeCompanySettingsFailureAction
  | GetPredefinedEmployeeSettingsAction
  | GetPredefinedEmployeeSettingsSuccessAction
  | GetPredefinedEmployeeSettingsFailureAction
  | GetAdvancedSettingsAction
  | GetAdvancedSettingsSuccessAction
  | GetAdvancedSettingsFailureAction
  | ChangeAdvancedSettingsAction
  | ChangeAdvancedSettingsSuccessAction
  | ChangeAdvancedSettingsFailureAction
  | GetBillingSettingsAction
  | GetBillingSettingsSuccessAction
  | GetBillingSettingsFailureAction
  | ChangeBillingSettingsAction
  | ChangeBillingSettingsSuccessAction
  | ChangeBillingSettingsFailureAction
  | GetIntegrationSettingsAction
  | GetIntegrationSettingsSuccessAction
  | GetIntegrationSettingsFailureAction
  | ChangeIntegrationSettingsAction
  | ChangeIntegrationSettingsSuccessAction
  | ChangeIntegrationSettingsFailureAction;

<h1 mat-dialog-title translate>userAdmins.editAdminHeader</h1>

<div mat-dialog-content class="content">
  <form [formGroup]="form" (submit)="submit()" class="form">
    <mat-form-field appearance="fill">
      <mat-label>{{'addons.links.name' | translate}}</mat-label>
      <input matInput formControlName="email" type="text">
      <mat-error *ngIf="form.hasError('required', ['email'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('email', ['email'])">{{'emailInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'userAdmins.role' | translate}}</mat-label>
      <mat-select formControlName="role">
        <mat-option value="SUPER_ADMIN" *ngIf="auth.isSuperAdmin">SUPER_ADMIN</mat-option>
        <mat-option value="COMPANY_MANAGER">COMPANY_MANAGER</mat-option>
        <mat-option value="PARTNER" *ngIf="auth.isSuperAdmin || auth.isPartner">PARTNER</mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', ['role'])">{{'fieldRequired' | translate}}</mat-error>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button (click)="close(false)">{{'cancel' | translate}}</button>
  <app-progress-button label="save" (buttonClick)="submit()" [loading]="sendingRequest"></app-progress-button>
</div>
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { AdvancedSettings } from "../settings/settings.interface";
import {
  ChangeAdvancedSettingsAction,
  GetAdvancedSettingsAction,
} from "../settings/settings.actions";
import { all, CountryData } from "country-codes-list";
import { getAdvancedSettingsSelector } from "../settings/settings.reducer";
import { getCurrentAccount } from "../accounts/accounts.reducer";

@Component({
  selector: "app-advanced-settings",
  templateUrl: "./advanced-settings.component.html",
  styleUrls: ["./advanced-settings.component.scss"],
})
export class AdvancedSettingsComponent implements OnInit {
  countries: CountryData[] = all()
    .map((country) => ({
      ...country,
      name: country.countryNameLocal,
      countryCallingCodeNumber: Number(country.countryCallingCode),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  filteredCountries: CountryData[] = this.countries.slice();

  currentDomain: string | null = null;
  selectedLanguage: string;

  form = new UntypedFormGroup({
    overwritingDataByGoogleSync: new UntypedFormControl(),
    overwritingPhotoByGoogleSync: new UntypedFormControl(),
    updateSignatureWhenGoogleDataChange: new UntypedFormControl(),
    confirmationOfSignatureInstallationByUser: new UntypedFormControl(),
    bannerDisablingByUser: new UntypedFormControl(),
    defaultCountryCallingCode: new UntypedFormControl(),
    analyticLinksEnabled: new UntypedFormControl(),
    absenceEnabled: new UntypedFormControl(),
    absenceLabel: new UntypedFormControl(),
    dailySignatureReinstallEnabled: new UntypedFormControl(),
    signatureReinstallTime: new UntypedFormControl(),
    mobileLabel: new UntypedFormControl(),
    phoneLabel: new UntypedFormControl(),
    emailLabel: new UntypedFormControl(),
    language: new UntypedFormControl(),
  });

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.select(getCurrentAccount).subscribe((domain) => {
      this.currentDomain = domain;
    });

    this.store.dispatch(new GetAdvancedSettingsAction());
    this.store
      .select(getAdvancedSettingsSelector)
      .subscribe((advancedSettings: AdvancedSettings) => {
        if (advancedSettings) {
          this.fillForm(advancedSettings);
        }
      });

    this.form
      .get("dailySignatureReinstallEnabled")
      ?.valueChanges.subscribe((enabled) => {
        const signatureReinstallTimeControl = this.form.get(
          "signatureReinstallTime"
        );
        if (enabled) {
          signatureReinstallTimeControl?.setValidators([Validators.required]);
          signatureReinstallTimeControl?.setValue(this.getCurrentTime());
        }
        signatureReinstallTimeControl?.updateValueAndValidity();
      });
  }

  fillForm = (advancedSettings: AdvancedSettings) => {
    this.form.patchValue({
      overwritingDataByGoogleSync:
        advancedSettings.overwritingDataByGoogleSync || false,
      overwritingPhotoByGoogleSync:
        advancedSettings.overwritingPhotoByGoogleSync || false,
      updateSignatureWhenGoogleDataChange:
        advancedSettings.updateSignatureWhenGoogleDataChange || false,
      confirmationOfSignatureInstallationByUser:
        advancedSettings.confirmationOfSignatureInstallationByUser || false,
      bannerDisablingByUser: advancedSettings.bannerDisablingByUser || false,
      defaultCountryCallingCode: advancedSettings.defaultCountryCallingCode,
      analyticLinksEnabled: advancedSettings.analyticLinksEnabled || false,
      absenceEnabled: advancedSettings.absenceEnabled || false,
      absenceLabel: advancedSettings.absenceLabel || "",
      dailySignatureReinstallEnabled:
        advancedSettings.dailySignatureReinstallEnabled || false,
      signatureReinstallTime: advancedSettings.signatureReinstallTime || "",
      mobileLabel: advancedSettings.mobileLabel || "",
      phoneLabel: advancedSettings.phoneLabel || "",
      emailLabel: advancedSettings.emailLabel || "",
      language: advancedSettings.language || "",
    });
  };

  getCurrentTime(): string {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  onSubmit = () => {
    if (this.form.valid) {
      const formValue = this.form.value;

      this.store.dispatch(new ChangeAdvancedSettingsAction(formValue));
    } else {
      this.form.markAllAsTouched();
    }
  };
}

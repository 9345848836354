import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignatureInstallationStateService } from './signature-installation-state.service';
import { switchMap, takeUntil, filter, map } from 'rxjs/operators';
import {
  POLL_STATE_UNTIL_INSTALLATION_FINISHED,
  PollStateUntilInstallationFinishedAction,
  START_CHECKING_FOR_INSTALLATION_IN_PROGRESS,
  AddInstallationStateProgressAction,
  ResetInstallationStateAction,
  ADD_INSTALLATION_STATE_PROGRESS
} from './signature-installation-state.actions';
import { Subject, timer, concat, of } from 'rxjs';
import moment from 'moment';

@Injectable()
export class SignatureInstallationStateEffects {

  startCheckingForInstallationInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(START_CHECKING_FOR_INSTALLATION_IN_PROGRESS),
      switchMap(() => {
        const reset$ = of(new ResetInstallationStateAction());
        const initialTime = new Date();
        const kill$ = new Subject();

        const polling$ = timer(500, 1000).pipe(
          takeUntil(kill$),
          switchMap(() => {
            return this.signatureInstallationStateService.getInProgressState().pipe(
              switchMap(response => {
                if (!response?.length) {
                  return [];
                }
                const latestState = response[0];
                if (
                  latestState.status === 'IN_PROGRESS' ||
                  (latestState.modifiedAt && moment(latestState.modifiedAt).isAfter(initialTime))
                ) {
                  kill$.next(1);
                  return [
                    new PollStateUntilInstallationFinishedAction(),
                    new AddInstallationStateProgressAction(latestState)
                  ];
                }
                return [];
              })
            );
          })
        );
        return concat(reset$, polling$);
      })
    )
  );

  pollStateUntilInstallationFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POLL_STATE_UNTIL_INSTALLATION_FINISHED),
      switchMap(() => {
        const kill$ = new Subject();

        return timer(2000, 2000).pipe(
          takeUntil(kill$),
          switchMap(() =>
            this.signatureInstallationStateService.getInProgressState().pipe(
              switchMap(response => {
                const latestState = response?.[0];
                if (!latestState) {
                  kill$.next(1);
                  return [];
                }
                if (latestState.status !== 'IN_PROGRESS') {
                  kill$.next(1);
                }
                return [new AddInstallationStateProgressAction(latestState)];
              })
            )
          )
        );
      })
    )
  );

  finishInstallation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_INSTALLATION_STATE_PROGRESS),
      filter((action: AddInstallationStateProgressAction) => action.payload.status !== 'IN_PROGRESS'),
      switchMap((action) => {
        return timer(2000).pipe(
          switchMap(() => this.signatureInstallationStateService.getInProgressState()),
          map(response => response[0]),
          switchMap(latestState => {
            if (latestState?.status === 'IN_PROGRESS') {
              return of(new AddInstallationStateProgressAction(latestState));
            } else {
              return timer(7000).pipe(
                map(() => new ResetInstallationStateAction())
              );
            }
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private signatureInstallationStateService: SignatureInstallationStateService
  ) {}
}

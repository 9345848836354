<div class="handwritten-signature-wrapper">
  <div class="toggle-handwritten-signature">
    <mat-slide-toggle
      [checked]="useHandwrittenSignature"
      (change)="toggleHandwrittenSignature($event.checked)"
    >
      {{ 'employee.handwritten.handwrittenTitle' | translate }}
    </mat-slide-toggle>
  </div>

  <mat-accordion>
    <mat-expansion-panel
      hideToggle
      [disabled]="!useHandwrittenSignature"
      [expanded]="useHandwrittenSignature"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'employee.handwritten.label' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card-content>
        <div class="upload-block">
          <h3>{{ 'employee.handwritten.uploadTitle' | translate }}</h3>

          <input
            type="file"
            class="file-input"
            accept="image/*"
            (change)="onHandwrittenSignatureFileSelected($event)"
            #fileUpload
          />
          <div class="file-upload">
            <div class="file-content">
              <button
                type="button"
                mat-stroked-button
                color="primary"
                class="upload-btn"
                (click)="fileUpload.click()"
              >
                {{ 'employee.handwritten.chooseFile' | translate }}
                <mat-icon>attach_file</mat-icon>
              </button>
              <h6>
                {{ fileName || ('employee.handwritten.noFileSelected' | translate) }}
              </h6>
            </div>
          </div>

          <h6 class="hint">
            {{ 'employee.handwritten.supportedFormats' | translate }}
          </h6>

          <div class="uploaded-preview" *ngIf="uploadedImage">
            <h5>{{ 'employee.handwritten.previewAlt' | translate }}</h5>
            <img [src]="uploadedImage" alt="Preview" class="signature-preview" />
          </div>
        </div>

        <div class="generate-block">
          <h3>{{ 'employee.handwritten.generateTitle' | translate }}</h3>
          
          <mat-form-field appearance="fill" class="signature-name-field">
            <mat-label>{{ 'employee.handwritten.nameLabel' | translate }}</mat-label>
            <input matInput [formControl]="form.get('signatureNameInput')" />
          </mat-form-field>

          <h5>{{ 'employee.handwritten.templateChooserTitle' | translate }}</h5>
          <div class="template-chooser">
            <div class="template-list">
              <div
                class="template-item"
                *ngFor="let style of signatureStyles; let i = index"
              >
                <div class="radio-cell">
                  <mat-radio-button
                    [value]="style"
                    [checked]="selectedStyle === style"
                    (change)="onStyleSelected(style, i)"
                  >
                  </mat-radio-button>
                </div>
                <div class="preview-cell">
                  <img
                    *ngIf="generatedPreviews[i]"
                    [src]="generatedPreviews[i]"
                    alt="{{ 'employee.handwritten.previewAlt' | translate }}"
                    class="style-preview"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-expansion-panel>
  </mat-accordion>
</div>

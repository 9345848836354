import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AddAdminForm } from "../user-admins.interface";
import { UserAdminsService } from "../user-admins.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-user-admins-create",
  templateUrl: "./user-admins-create.component.html",
  styleUrls: ["./user-admins-create.component.scss"],
})
export class UserAdminsCreateComponent implements OnInit {
  public form: FormGroup<AddAdminForm>;
  public loading = false;

  constructor(
    private readonly service: UserAdminsService,
    private toast: ToastrService,
    private router: Router,
    private translate: TranslateService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    const defaultRole = this.auth.isPartner ? "PARTNER" : "COMPANY_MANAGER";
    this.form = new FormGroup<AddAdminForm>({
      email: new FormControl("", [Validators.required, Validators.email]),
      role: new FormControl(defaultRole, [Validators.required]),
    });
  }

  save() {
    this.loading = true;
    const form = this.form.getRawValue();

    this.service
      .addAdmin({ ...form, email: form.email.trim() })
      .toPromise()
      .then(() =>
        this.toast.success(this.translate.instant("userAdmins.addSuccess"))
      )
      .then(() => this.router.navigate(["/user-admins"]))
      .finally(() => (this.loading = false));
  }
}

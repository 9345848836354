import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentGoogleSyncStateSelector } from '../google-sync-state.reducer';
import { GoogleSyncState } from '../google-sync-state.interface';
import { GoogleSyncErrorDialogComponent } from '../google-sync-error-dialog.component/google-sync-error-dialog.component';

@Component({
  selector: 'app-google-sync-progress-toast',
  templateUrl: './google-sync-progress-toast.component.html',
  styleUrls: ['./google-sync-progress-toast.component.scss'],
})
export class GoogleSyncProgressToastComponent implements AfterViewInit {
  @ViewChild('snackbarTemplate') template: TemplateRef<any>;

  public isOpen = false;
  public currentState: GoogleSyncState | null = null;
  private currentSyncId: string | null = null;
  public errorCount: number = 0;

  public totalEmployees: number = 0;
  public totalAliases: number = 0;

  constructor(
    private readonly store: Store,
    private readonly snackbar: MatSnackBar,
    private readonly dialog: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.store.select(getCurrentGoogleSyncStateSelector).subscribe((result) => {
      if (!result) {
        this.currentState = null;
        this.currentSyncId = null;
        this.errorCount = 0;
        this.totalEmployees = 0;
        this.totalAliases = 0;
        if (this.isOpen) {
          this.isOpen = false;
          this.snackbar.dismiss();
        }
        return;
      }

      if (!this.currentSyncId || this.currentSyncId !== result.id) {
        this.currentSyncId = result.id || null;
        this.errorCount = 0;
        this.totalEmployees = 0;
        this.totalAliases = 0;
      }

      this.currentState = result;
      this.errorCount = result.syncs ? result.syncs.filter((s) => s.error).length : 0;

      const newTotalEmployees = result.totalEmployees || 0;
      const newTotalAliases = result.totalAliases || 0;

      if (newTotalEmployees !== this.totalEmployees) {
        this.animateCounter('totalEmployees', this.totalEmployees, newTotalEmployees, 2000);
      }
      if (newTotalAliases !== this.totalAliases) {
        this.animateCounter('totalAliases', this.totalAliases, newTotalAliases, 2000);
      }

      if (!this.isOpen && (newTotalEmployees > 0 || newTotalAliases > 0)) {
        this.isOpen = true;
        this.snackbar.openFromTemplate(this.template);
      }
    });
  }

  get syncedEmployeesCount(): number {
    if (this.currentState && this.currentState.syncs) {
      const count = this.currentState.syncs.filter((s) => !s.isAlias && !s.error).length;
      return count;
    }
    return 0;
  }

  get syncedAliasesCount(): number {
    if (this.currentState && this.currentState.syncs) {
      return this.currentState.syncs.filter((s) => s.isAlias && !s.error).length;
    }
    return 0;
  }

  private animateCounter(
    property: 'totalEmployees' | 'totalAliases',
    from: number,
    to: number,
    duration: number = 2000
  ): void {
    const steps = 20;
    const stepTime = duration / steps;
    const increment = (to - from) / steps;
    let currentStep = 0;
    const intervalId = setInterval(() => {
      currentStep++;
      this[property] = Math.round(from + increment * currentStep);
      if (currentStep >= steps) {
        clearInterval(intervalId);
        this[property] = to;
      }
    }, stepTime);
  }

  close() {
    this.isOpen = false;
    this.snackbar.dismiss();
  }

  showErrors() {
    this.isOpen = false;
    this.snackbar.dismiss();
    if (this.currentState) {
      this.dialog.open(GoogleSyncErrorDialogComponent, { data: this.currentState });
    }
  }
}

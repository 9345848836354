import { Component, OnInit } from "@angular/core";
import { UserRole } from "./user-admins.interface";
import { UserAdminsService } from "./user-admins.service";
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "../auth/auth.service";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ToastrTranslatedService } from "../shared/toastr-translated.service";
import { UserAdminsEditDialogComponent } from "./user-admins-edit-dialog/user-admins-edit-dialog.component";

@Component({
  selector: "app-user-admins",
  templateUrl: "./user-admins.component.html",
  styleUrls: ["./user-admins.component.scss"],
})
export class UserAdminsComponent implements OnInit {
  public displayedColumns = [
    "email",
    "role",
    "createdAt",
    "activityTime",
    "id",
  ];
  public dataSource = new MatTableDataSource<UserRole>();

  constructor(
    private readonly service: UserAdminsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrTranslatedService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.fetchAdmins();
  }

  fetchAdmins = () => {
    this.service
      .fetchAdmins()
      .subscribe((admins) => (this.dataSource.data = admins));
  };

  removeAdmin = (id) => {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant("userAdmins.deleteConfirmationText"),
        },
      })
      .afterClosed()
      .subscribe(async (confirm) => {
        if (confirm) {
          await this.service
            .removeAdmin(id)
            .toPromise()
            .then(() => {
              this.fetchAdmins();
              this.toastr.success(
                this.translate.instant("userAdmins.deleteSuccess")
              );
            });
        }
      });
  };

  editAdmin = (userRole: UserRole) => {
    this.dialog
      .open(UserAdminsEditDialogComponent, { data: userRole })
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          userRole.email = response.email;
          userRole.role = response.role;
          this.toastr.success(
            this.translate.instant("userAdmins.changeSuccess")
          );
        }
      });
  };
}

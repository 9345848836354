import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { getCurrentAccount } from "../../accounts/accounts.reducer";
import { BillingMonthlyStats } from "../../settings/settings.interface";
import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { BillingSettings } from "../../settings/settings.interface";
import { BillingSettingsService } from "./billing-settings.service";
import {
  GetBillingSettingsAction,
  ChangeBillingSettingsAction,
} from "../../settings/settings.actions";
import { getBillingSettingsSelector } from "../../settings/settings.reducer";
import { AuthService } from "../../auth/auth.service";
import {
  Country,
  COUNTRIES_DB,
} from "@angular-material-extensions/select-country";
import { skip } from "rxjs";

@Component({
  selector: "app-billing-settings",
  templateUrl: "./billing-settings.component.html",
  styleUrls: ["./billing-settings.component.scss"],
})
export class BillingSettingsComponent implements OnInit {
  currentDomain: string | null = null;

  monthlyStats: BillingMonthlyStats[] = [];
  displayedColumns: string[] = ["date", "numberOfEmployees", "price"];

  yearlyStats: any[] = [];

  plans = ["gSignature Basic", "gSignature Plus", "gSignature Pro"];
  currencies = ["USD", "EUR", "PLN"];

  paymentMethod: string;
  isProcessingPayment = false;

  countriesList: Country[] = [];
  countrySearchControl = new FormControl("");
  filteredCountries: Country[] = [];

  allSubdomains: Array<{ domain: string }> = [];
  filteredSubdomains: Array<{ domain: string }> = [];
  selectedSubdomainsControl = new UntypedFormControl([]);
  selectedSubdomainsSet = new Set<string>();
  searchControl = new FormControl("");

  isMainDomain = false;
  subdomainsStatsMap: { [domain: string]: BillingMonthlyStats[] } = {};
  aggregatedStats: BillingMonthlyStats[] = [];
  currentSubdomains: Array<{ domain: string }> = [];

  form = new UntypedFormGroup({
    planName: new UntypedFormControl({ value: "", disabled: true }),
    price: new UntypedFormControl({ value: "", disabled: true }, [
      Validators.pattern(/^\d+(\.\d{1,2})?$/),
    ]),
    currency: new UntypedFormControl({ value: "", disabled: true }),
    planId: new UntypedFormControl({ value: "", disabled: true }),
    subscriptionStartDate: new UntypedFormControl({
      value: "",
      disabled: true,
    }),
    lastInvoiceUserCount: new UntypedFormControl({ value: "", disabled: true }),
    currentUserCount: new UntypedFormControl({ value: "", disabled: true }),
    subscriptionPeriod: new UntypedFormControl({ value: "", disabled: true }),
    yearlyUserCountRange: new UntypedFormControl({ value: 0, disabled: true }),
    contactName: new UntypedFormControl(""),
    contactPhone: new UntypedFormControl(""),
    contactEmail: new UntypedFormControl(""),
    companyName: new UntypedFormControl(""),
    country: new UntypedFormControl(""),
    street: new UntypedFormControl(""),
    streetNumber: new UntypedFormControl(""),
    city: new UntypedFormControl(""),
    postalCode: new UntypedFormControl(""),
    state: new UntypedFormControl(""),
    vatNumber: new UntypedFormControl(""),
    vatRate: new UntypedFormControl({ value: 0, disabled: true }),
  });

  constructor(
    private billingSettingsService: BillingSettingsService,
    private store: Store,
    public auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.store.select(getCurrentAccount).subscribe((domain) => {
      this.currentDomain = domain;
    });

    if (this.auth.isSuperAdmin) {
      this.enableFieldsForSuperAdmin();
    }

    this.store.dispatch(new GetBillingSettingsAction());
    this.store
      .select(getBillingSettingsSelector)
      .subscribe((billingSettings: BillingSettings) => {
        if (billingSettings && this.currentDomain) {
          this.fillForm(billingSettings);
          if (this.auth.isSuperAdmin) {
            this.loadDomainsAndSubdomains(billingSettings);
          }
          this.initializeStatsView(billingSettings);
        }
      });

    this.countriesList = COUNTRIES_DB;
    this.filteredCountries = [...this.countriesList];
    this.countrySearchControl.valueChanges.subscribe(() =>
      this.filterCountries()
    );

    this.searchControl.valueChanges.subscribe(() => this.filterSubdomains());

    this.form.get('yearlyUserCountRange')?.valueChanges
      .pipe(skip(1))
      .subscribe((value) => {
        this.updatePriceBasedOnUserCountRange(value);
      });
  }

  loadYearlyStats(): void {
    this.billingSettingsService
      .getYearlyStats(this.currentDomain!)
      .subscribe((stats: any[]) => {
        this.yearlyStats = stats;
      });
  }

  filterSubdomains() {
    const searchValue = this.searchControl.value?.toLowerCase() || "";

    this.filteredSubdomains = this.allSubdomains.filter(
      (sd) =>
        (sd.domain.toLowerCase().includes(searchValue) ||
          this.selectedSubdomainsSet.has(sd.domain)) &&
        sd.domain !== this.currentDomain
    );
  }

  onSelectionChange(event: any) {
    const selectedValues = event.value as Array<{ domain: string }>;
    this.selectedSubdomainsSet.clear();

    selectedValues.forEach((selected) => {
      this.selectedSubdomainsSet.add(selected.domain);
    });
  }

  private initializeStatsView(billingSettings: BillingSettings) {
    if (billingSettings.subscriptionPeriod === "yearly") {
      this.billingSettingsService
        .getYearlyStats(this.currentDomain!)
        .subscribe((stats: BillingMonthlyStats[]) => {
          this.yearlyStats = stats;
        });
    } else {
      this.billingSettingsService
        .getMonthlyStats(this.currentDomain!)
        .subscribe((stats: BillingMonthlyStats[]) => {
          this.monthlyStats = stats;
        });
    }

    const hasSubdomains = billingSettings.subdomains && billingSettings.subdomains.length > 0;
    if (hasSubdomains) {
      this.isMainDomain = true;
      this.currentSubdomains = billingSettings.subdomains || [];
      this.currentSubdomains.forEach((sd) => {
        if (billingSettings.subscriptionPeriod === "yearly") {
          this.billingSettingsService
            .getYearlyStats(sd.domain)
            .subscribe((subStats: BillingMonthlyStats[]) => {
              this.subdomainsStatsMap[sd.domain] = subStats;
            });
        } else {
          this.billingSettingsService
            .getMonthlyStats(sd.domain)
            .subscribe((subStats: BillingMonthlyStats[]) => {
              this.subdomainsStatsMap[sd.domain] = subStats;
            });
        }
      });
    }
    this.billingSettingsService
      .getAggregatedMonthlyStats(this.currentDomain!)
      .subscribe((aggStats: BillingMonthlyStats[]) => {
        this.aggregatedStats = aggStats;
      });
  }

  fillForm = (billingSettings: BillingSettings) => {
    this.form.patchValue({
      planName: billingSettings.planName,
      price: billingSettings.price,
      currency: billingSettings.currency,
      planId: billingSettings.planId,
      subscriptionStartDate: new Date(billingSettings.subscriptionStartDate),
      lastInvoiceUserCount: billingSettings.lastInvoiceUserCount,
      currentUserCount: billingSettings.currentUserCount,
      subscriptionPeriod: billingSettings.subscriptionPeriod,
      yearlyUserCountRange: billingSettings.yearlyUserCountRange,
      contactName: billingSettings.contactName || "",
      contactPhone: billingSettings.contactPhone || "",
      contactEmail: billingSettings.contactEmail || "",
      companyName: billingSettings.companyName || "",
      country: billingSettings.country || "",
      street: billingSettings.street || "",
      streetNumber: billingSettings.streetNumber || "",
      city: billingSettings.city || "",
      postalCode: billingSettings.postalCode || "",
      state: billingSettings.state || "",
      vatNumber: billingSettings.vatNumber || "",
      vatRate: billingSettings.vatRate,
    });

    this.paymentMethod = billingSettings.paymentMethod;
  };

  subdomainsForm = new UntypedFormGroup({
    selectedSubdomains: new UntypedFormControl([]),
  });

  private loadDomainsAndSubdomains(billingSettings: BillingSettings): void {
    this.billingSettingsService.getAllDomains().subscribe({
      next: (domains) => {
        this.allSubdomains = domains.map((domain) => ({ domain }));
        this.filteredSubdomains = this.allSubdomains.filter(
          (sd) => sd.domain !== this.currentDomain
        );
        this.loadSavedSubdomains(billingSettings.subdomains || []);
      },
    });
  }

  private loadSavedSubdomains(subdomains: Array<{ domain: string }>): void {
    const matchedSubdomains = subdomains
      .map((selected) =>
        this.allSubdomains.find(
          (subdomain) => subdomain.domain === selected.domain
        )
      )
      .filter(Boolean);

    this.subdomainsForm.get("selectedSubdomains")?.setValue(matchedSubdomains);

    matchedSubdomains.forEach((subdomain) => {
      if (subdomain) {
        this.selectedSubdomainsSet.add(subdomain.domain);
      }
    });
  }

  savePaymentMethod(): void {
    this.billingSettingsService
      .updatePaymentMethod(this.paymentMethod)
      .subscribe({
        next: () => {
          this.toastr.success(
            this.translate.instant(
              "settings.billing.paymentMethodUpdateSuccess"
            )
          );
        },
        error: () => {
          this.toastr.error(
            this.translate.instant("settings.billing.paymentMethodUpdateError")
          );
        },
      });
  }

  saveSubdomainsSelection(): void {
    const selected = this.subdomainsForm.get("selectedSubdomains")?.value || [];
    this.billingSettingsService
      .updateSubdomains(this.currentDomain!, selected)
      .subscribe(() => {
        this.toastr.success(
          this.translate.instant("settings.billing.subdomainsSuccess")
        );
      });
  }

  enableFieldsForSuperAdmin() {
    [
      "planName",
      "price",
      "currency",
      "subscriptionPeriod",
      "yearlyUserCountRange",
      "subscriptionStartDate",
      "vatRate",
    ].forEach((field) => this.form.get(field)?.enable());
  }

  onSubmit = () => {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();

      if (formValue.subscriptionStartDate instanceof Date) {
        formValue.subscriptionStartDate =
          formValue.subscriptionStartDate.toISOString();
      } else {
        formValue.subscriptionStartDate =
          formValue.subscriptionStartDate.format("YYYY-MM-DD");
      }

      this.store.dispatch(new ChangeBillingSettingsAction(formValue));

      this.toastr.success(
        this.translate.instant("settings.billing.updateSuccess")
      );
    } else {
      this.form.markAllAsTouched();
    }
  };

  async openPaymentModal(): Promise<void> {
    if (this.paymentMethod !== "card") {
      this.toastr.info(
        this.translate.instant("settings.billing.invoiceSelected")
      );
      return;
    }

    this.isProcessingPayment = true;

    this.billingSettingsService.validateBillingInfo().subscribe({
      next: (response: { success: boolean; missingFields?: string[] }) => {
        if (response.success) {
          const subscriptionPeriod = this.form.get("subscriptionPeriod")?.value;
          const flowType = subscriptionPeriod === "yearly" ? "yearly" : "billing";

          this.billingSettingsService.createCheckoutSession(flowType).subscribe({
            next: (stripeResponse: { url: string }) => {
              window.location.href = stripeResponse.url;
            },
            error: () => {
              this.toastr.error(
                this.translate.instant("settings.billing.paymentError")
              );
              this.isProcessingPayment = false;
            },
          });
        } else {
          this.toastr.warning(
            this.translate.instant("settings.billing.fillRequiredFields")
          );
          setTimeout(() => {
            const formSection = document.getElementById("billing-info-section");
            if (formSection) {
              formSection.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }, 200);
          this.highlightMissingFields(response.missingFields || []);
          this.isProcessingPayment = false;
        }
      },
      error: () => {
        this.toastr.error(
          this.translate.instant("settings.billing.validationError")
        );
        this.isProcessingPayment = false;
      },
    });
  }

  updatePriceBasedOnUserCountRange(value: number | string): void {
    const subscriptionType = this.form.get('subscriptionPeriod')?.value;
    const parsedSeats = parseInt(value as string, 10);
    let price = 1;
  
    if (subscriptionType === 'monthly') {
      const monthlyPrices: Record<number, number> = {
        100: 1,
        500: 1,
        1000: 0.85,
        2000: 0.70,
        5000: 0.60,
        10000: 0.40,
      };
      price = monthlyPrices[parsedSeats] || 0;
    } else if (subscriptionType === 'yearly') {
      const yearlyPrices: Record<number, number> = {
        100: 0.80,
        500: 0.80,
        1000: 0.68,
        2000: 0.56,
        5000: 0.48,
        10000: 0.32,
      };
      price = yearlyPrices[parsedSeats] || 0;
    }
  
    this.form.get('price')?.setValue(price);
  }
  
  highlightMissingFields(missingFields: string[]): void {
    missingFields.forEach((field) => {
      const formControl = this.form.get(field);
      if (formControl) {
        formControl.markAsTouched();
        formControl.setErrors({ required: true });

        const matFormField = document.querySelector(
          `mat-form-field[formControlName="${field}"]`
        );
        if (matFormField) {
          matFormField.classList.add("mat-form-field-error");
        }
      }
    });
  }

  onCountryChange(selectedCountryCode: string) {
    const selectedCountry = this.filteredCountries.find(
      (c) => c.alpha2Code === selectedCountryCode
    );

    if (selectedCountry) {
      if (selectedCountry.alpha2Code === "PL") {
        this.form.get("vatRate")?.setValue(23);
      } else {
        this.form.get("vatRate")?.setValue(0);
      }
    }
  }

  filterCountries(): void {
    const searchValue = this.countrySearchControl.value?.toLowerCase() || "";

    if (!searchValue) {
      this.filteredCountries = this.countriesList;
    } else {
      this.filteredCountries = this.countriesList.filter((country) =>
        country.name.toLowerCase().includes(searchValue)
      );
    }

    const selectedCountryCode = this.form.get("country")?.value;
    if (
      selectedCountryCode &&
      !this.filteredCountries.some((c) => c.alpha2Code === selectedCountryCode)
    ) {
      const selectedCountry = this.countriesList.find(
        (c) => c.alpha2Code === selectedCountryCode
      );
      if (selectedCountry) {
        this.filteredCountries.unshift(selectedCountry);
      }
    }
  }

  getCountryName(alpha2Code: string): string {
    const country = this.countriesList.find((c) => c.alpha2Code === alpha2Code);
    return country ? country.name : "";
  }

  getCountryFlagUrl(alpha2Code: string): string {
    return `https://flagcdn.com/w40/${alpha2Code.toLowerCase()}.png`;
  }
}

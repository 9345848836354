import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SignaturePredefined} from '../signatures.interface';
import {SignaturesService, wyswigConfig} from '../signatures.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-signature-create',
  templateUrl: './signature-create.component.html',
  styleUrls: ['./signature-create.component.scss']
})
export class SignatureCreateComponent implements OnInit {

  form = new UntypedFormGroup({
    content: new UntypedFormControl(''),
    name: new UntypedFormControl('', Validators.required),
    addonBannerId: new UntypedFormControl(''),
    addonAdditionalBannerId: new UntypedFormControl(''),
    addonLinkId: new UntypedFormControl(''),
    addonDisclaimerId: new UntypedFormControl(''),
    userAvatarWidth: new UntypedFormControl(null, Validators.min(0)),
    userAvatarHeight: new UntypedFormControl(null, Validators.min(0)),
    userAvatarBorderRadius: new UntypedFormControl(null, [Validators.min(0), Validators.max(100)]),
    phoneLabel: new UntypedFormControl(''),
    mobileLabel: new UntypedFormControl(''),
    emailLabel: new UntypedFormControl(''),
    trialSignature: new UntypedFormControl({ value: true, disabled: true })
  });

  setting = false;
  config = wyswigConfig;

  activeSection: 'addons' | 'dynamicVariables' | null = null;
  addonsLoaded = false;

  isTrialSignature = false;

  public predefined$: Observable<SignaturePredefined[]>;

  constructor(
    private auth: AuthService,
    private service: SignaturesService,
    private toast: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.service.getClientPlanId().subscribe(res => {
      this.isTrialSignature = res.isFreePlan;
      if (!this.isTrialSignature || this.auth.isSuperAdmin) {
        this.form.get('trialSignature').enable();
      }
    });
    
    this.predefined$ = this.service.predefined();
    this.addonsLoaded = true;
  }

  toggleSection(section: 'addons' | 'dynamicVariables'): void {
    this.activeSection = this.activeSection === section ? null : section;
  }

  save(installSignature = false) {
    this.setting = true;
    this.service.save(this.form.getRawValue()).toPromise()
      .then((response: { id: string }) => {
        this.toast.success(this.translate.instant('footers.added'));
        if (installSignature) {
          this.router.navigate(['signatures', response.id]);
        } else {
          this.goToSignatures();
        }
      })
      .finally(() => this.setting = false);
  }

  saveAndInstall() {
    this.save(true);
  }

  load = (tpl: any) => this.form.patchValue({content: tpl.content});

  goToSignatures = () => {
    this.router.navigate(['signatures']);
  };

}

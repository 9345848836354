<div class="expired-trial-wrapper">
  <div class="content">
    <h1 class="title">{{ 'trial.expired.title' | translate }}</h1>

    <div *ngIf="auth.isSuperAdmin || auth.isCompanyManager || auth.isPartner; else noRole">
      <p class="description">{{ 'trial.expired.message' | translate }}</p>

      <ng-container>
        <div class="actions">
          <button mat-raised-button color="primary" type="button"
                  (click)="openPaymentModal()"
                  [disabled]="isProcessingPayment"
                  class="payment-button">
            <mat-icon>credit_card</mat-icon>
            {{ 'trial.expired.addCard' | translate }}
            <div class="spinner-border spinner-border-sm" role="status" *ngIf="isProcessingPayment"></div>
          </button>
        </div>
  
        <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
          <mat-accordion multi>
            <mat-expansion-panel #planPanel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'settings.billing.yourPlan' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.planName' | translate }}</mat-label>
                  <mat-select formControlName="planName">
                    <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan }}</mat-option>
                  </mat-select>
                </mat-form-field>
                
                <div class="price-row">
                  <mat-form-field appearance="fill" class="price-field">
                    <mat-label>{{ 'settings.billing.price' | translate }}</mat-label>
                    <input matInput formControlName="price" type="number" step="0.01" min="0">
                  </mat-form-field>
                
                  <mat-form-field appearance="fill" class="currency-field">
                    <mat-label>{{ 'settings.billing.currency' | translate }}</mat-label>
                    <mat-select formControlName="currency">
                      <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.planId' | translate }}</mat-label>
                  <input matInput formControlName="planId" disabled>
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.subscriptionStartDate' | translate }}</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="subscriptionStartDate">
                  <mat-hint>YYYY-MM-DD</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.lastInvoiceUserCount' | translate }}</mat-label>
                  <input matInput formControlName="lastInvoiceUserCount" type="number" min="0">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.currentUserCount' | translate }}</mat-label>
                  <input matInput formControlName="currentUserCount" type="number" min="0">
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          
            <mat-expansion-panel #contactPanel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ 'settings.billing.contactInfo' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel-content" id="billing-info-section">
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.contactName' | translate }}</mat-label>
                  <input matInput formControlName="contactName">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.contactPhone' | translate }}</mat-label>
                  <input matInput formControlName="contactPhone">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.contactEmail' | translate }}</mat-label>
                  <input matInput formControlName="contactEmail">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.companyName' | translate }}</mat-label>
                  <input matInput formControlName="companyName">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.country' | translate }}</mat-label>
                  <input matInput formControlName="country">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.street' | translate }}</mat-label>
                  <input matInput formControlName="street">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.streetNumber' | translate }}</mat-label>
                  <input matInput formControlName="streetNumber">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.city' | translate }}</mat-label>
                  <input matInput formControlName="city">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.postalCode' | translate }}</mat-label>
                  <input matInput formControlName="postalCode">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.state' | translate }}</mat-label>
                  <input matInput formControlName="state">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.vatNumber' | translate }}</mat-label>
                  <input matInput formControlName="vatNumber">
                </mat-form-field>
                
                <mat-form-field appearance="fill">
                  <mat-label>{{ 'settings.billing.vatRate' | translate }}</mat-label>
                  <input matInput formControlName="vatRate" type="number" step="1" min="0" max="100">
                </mat-form-field>
  
                <div class="actions">
                  <button mat-raised-button type="submit">{{ 'save' | translate }}</button>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </ng-container>
    </div>

    <ng-template #noRole>
      <p class="info-message">
        {{ 'trial.expired.userInfo' | translate }}
      </p>
    </ng-template>

    <p class="contact-info">
      {{ 'trial.expired.needHelp' | translate }} <a href="mailto:contact&#64;gsignature.com">contact&#64;gsignature.com</a>
    </p>
  </div>
</div>

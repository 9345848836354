<!-- <nav mat-tab-nav-bar mat-stretch-tabs="false">
  <a mat-tab-link
     routerLinkActive
     #rla1="routerLinkActive"
     [active]="rla1.isActive"
     routerLink="custom">{{'menu.signatures.custom' | translate}}</a>

  <a mat-tab-link
     routerLinkActive
     #rla2="routerLinkActive"
     [active]="rla2.isActive"
     routerLink="library">{{'menu.signatures.library' | translate}}</a>

</nav> -->

<router-outlet></router-outlet>

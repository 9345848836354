<div class="billing-settings">
  <div class="form">
    <div class="section-label">
      {{ 'settings.billing.paymentMethod' | translate }}
    </div>

    <mat-divider></mat-divider>

    <mat-radio-group [value]="paymentMethod" (change)="paymentMethod = $event.value" [disabled]="!auth.isSuperAdmin">
      <ng-container *ngIf="auth.isSuperAdmin || paymentMethod === 'invoice'">
        <mat-radio-button value="invoice">
          {{ 'settings.billing.invoice' | translate }}
        </mat-radio-button>
        <div class="payment-description">
          {{ 'settings.billing.invoiceDescription' | translate }}
        </div>
      </ng-container>
      <mat-radio-button value="card">
        {{ 'settings.billing.card' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <div>
      <button mat-raised-button color="primary" type="button" (click)="openPaymentModal()"
        [disabled]="paymentMethod !== 'card' || isProcessingPayment" class="payment-button">
        <mat-icon>credit_card</mat-icon>
        {{ paymentMethod === 'card' ? ('settings.billing.proceedToPayment' | translate) :
        ('settings.billing.selectCardOption' | translate) }}
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="isProcessingPayment"></div>
      </button>
    </div>

    <div *ngIf="auth.isSuperAdmin" class="actions">
      <button mat-raised-button type="button" (click)="savePaymentMethod()">
        {{ 'settings.billing.savePaymentMethod' | translate }}
      </button>
    </div>
  </div>

  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">

    <div class="section-label">
      {{ 'settings.billing.yourPlan' | translate }}
    </div>

    <mat-divider></mat-divider>

    <div class="price-row">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.planName' | translate }}</mat-label>
        <mat-select formControlName="planName">
          <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.subscriptionPeriod' | translate }}</mat-label>
        <mat-select formControlName="subscriptionPeriod">
          <mat-option value="monthly">{{ 'settings.billing.monthly' | translate }}</mat-option>
          <mat-option value="yearly">{{ 'settings.billing.yearly' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="price-row">
      <mat-form-field appearance="fill" class="price-field">
        <mat-label>{{ 'settings.billing.price' | translate }}</mat-label>
        <input matInput formControlName="price" type="number" step="0.01" min="0">
      </mat-form-field>

      <mat-form-field appearance="fill" class="currency-field">
        <mat-label>{{ 'settings.billing.currency' | translate }}</mat-label>
        <mat-select formControlName="currency">
          <mat-option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.planId' | translate }}</mat-label>
      <input matInput formControlName="planId" disabled>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.subscriptionStartDate' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="subscriptionStartDate">
      <mat-hint>YYYY-MM-DD</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div *ngIf="form.get('subscriptionPeriod')?.value === 'monthly'">
      <div class="price-row">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'settings.billing.lastInvoiceUserCount' | translate }}</mat-label>
          <input matInput formControlName="lastInvoiceUserCount" type="number" min="0">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ 'settings.billing.currentUserCount' | translate }}</mat-label>
          <input matInput formControlName="currentUserCount" type="number" min="0">
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="form.get('subscriptionPeriod')?.value === 'yearly'">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>{{ 'settings.billing.yearlyUserCountRange' | translate }}</mat-label>
        <mat-select formControlName="yearlyUserCountRange">
          <mat-option [value]="200">1-200</mat-option>
          <mat-option [value]="500">201-500</mat-option>
          <mat-option [value]="1000">501-1000</mat-option>
          <mat-option [value]="2000">1001-2000</mat-option>
          <mat-option [value]="5000">2001-5000</mat-option>
          <mat-option [value]="10000">5001-10000</mat-option>
          <mat-option [value]="10001">10000+</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div id="billing-info-section" class="section-label">
      {{ 'settings.billing.contactInfo' | translate }}
    </div>

    <mat-divider></mat-divider>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactName' | translate }}</mat-label>
      <input matInput formControlName="contactName">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactPhone' | translate }}</mat-label>
      <input matInput formControlName="contactPhone">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.contactEmail' | translate }}</mat-label>
      <input matInput formControlName="contactEmail">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.companyName' | translate }}</mat-label>
      <input matInput formControlName="companyName">
    </mat-form-field>

    <div class="address-row">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.country' | translate }}</mat-label>
        <mat-select formControlName="country" (selectionChange)="onCountryChange($event.value)">
          <div class="search-container search-field">
            <input matInput type="text" [formControl]="countrySearchControl" (input)="filterCountries()">
          </div>
          <mat-select-trigger>
            <ng-container *ngIf="form.get('country')?.value as selectedCode">
              <img [src]="getCountryFlagUrl(selectedCode)" width="20" height="15"
                style="margin-right: 5px; vertical-align: middle;">
              {{ getCountryName(selectedCode) }}
            </ng-container>
          </mat-select-trigger>
          <mat-option *ngFor="let country of filteredCountries" [value]="country.alpha2Code">
            <img [src]="getCountryFlagUrl(country.alpha2Code)" width="20" height="15"
              style="margin-right: 5px; vertical-align: middle;">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.city' | translate }}</mat-label>
        <input matInput formControlName="city">
      </mat-form-field>
    </div>

    <div class="address-row">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.street' | translate }}</mat-label>
        <input matInput formControlName="street">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.streetNumber' | translate }}</mat-label>
        <input matInput formControlName="streetNumber">
      </mat-form-field>
    </div>

    <div class="address-row">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.postalCode' | translate }}</mat-label>
        <input matInput formControlName="postalCode">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ 'settings.billing.state' | translate }}</mat-label>
        <input matInput formControlName="state">
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.vatNumber' | translate }}</mat-label>
      <input matInput formControlName="vatNumber">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'settings.billing.vatRate' | translate }}</mat-label>
      <input matInput formControlName="vatRate" type="number" step="1" min="0" max="100"
        [disabled]="!auth.isSuperAdmin">
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button type="submit">{{ 'save' | translate }}</button>
    </div>
  </form>

  <div class="section-report">
    <div class="section-label">
      {{ 'settings.billing.report' | translate }}
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="auth.isSuperAdmin">
      <form [formGroup]="subdomainsForm" class="form" (ngSubmit)="saveSubdomainsSelection()">
        <div class="subdomain-selection">
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>{{ 'settings.billing.chooseSubdomains' | translate }}</mat-label>
            <mat-select formControlName="selectedSubdomains" multiple (selectionChange)="onSelectionChange($event)">
              <div class="search-container search-field">
                <input matInput type="text" [formControl]="searchControl" (input)="filterSubdomains()">
              </div>

              <mat-option *ngFor="let sd of filteredSubdomains" [value]="sd">
                {{ sd.domain }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="subdomain-selection">
            <button mat-raised-button type="submit">
              {{ 'settings.billing.saveSelection' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="monthly-stats">
      <ng-container *ngIf="isMainDomain && currentSubdomains && currentSubdomains.length > 0">
        <h3>{{ 'settings.billing.aggregatedStats' | translate }}</h3>
        <div class="monthly-stats-table-container">
          <table mat-table [dataSource]="aggregatedStats">

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>
                {{ form.get('subscriptionPeriod')?.value === 'yearly'
                ? ('settings.billing.yearlyDate' | translate)
                : ('settings.billing.lastDayOfMonth' | translate) }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="numberOfEmployees">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="form.get('subscriptionPeriod')?.value === 'yearly'">
        <h3>{{ 'settings.billing.yearlyStats' | translate }}</h3>
        <h4>{{ currentDomain }}</h4>
        <div class="monthly-stats-table-container">
          <table mat-table [dataSource]="yearlyStats">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.yearlyDate' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
            </ng-container>
            <ng-container matColumnDef="numberOfEmployees">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
              <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </ng-container>

      <ng-container *ngIf="form.get('subscriptionPeriod')?.value !== 'yearly'">
        <ng-container>
          <h3>{{ 'settings.billing.monthlyStats' | translate }}</h3>
          <h4>{{ currentDomain }}</h4>
          <div class="monthly-stats-table-container">
            <table mat-table [dataSource]="monthlyStats">
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.lastDayOfMonth' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
              </ng-container>
              <ng-container matColumnDef="numberOfEmployees">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isMainDomain && currentSubdomains && currentSubdomains.length > 0">
        <h3>{{ 'settings.billing.subdomainStats' | translate }}</h3>
        <div *ngFor="let sd of currentSubdomains" class="monthly-stats-subdomain">
          <h4>{{ sd.domain }}</h4>
          <div class="monthly-stats-table-container">
            <table mat-table [dataSource]="subdomainsStatsMap[sd.domain]">
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                  {{ form.get('subscriptionPeriod')?.value === 'yearly'
                  ? ('settings.billing.yearlyDate' | translate)
                  : ('settings.billing.lastDayOfMonth' | translate) }}
                </th>
                <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd.MM.yyyy' }}</td>
              </ng-container>

              <ng-container matColumnDef="numberOfEmployees">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.numberOfAccounts' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.numberOfEmployees }}</td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>{{ 'settings.billing.serviceValue' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.price }} {{ element.currency }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
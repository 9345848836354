<mat-toolbar>
  <span translate>userAdmins.new</span>
</mat-toolbar>

<div class="form-wrapper">
  <p translate class="description body-1" *ngIf="auth.isSuperAdmin">userAdmins.newDescription</p>

  <form [formGroup]="form" class="form" (ngSubmit)="save()">
    <mat-form-field appearance="fill">
      <mat-label>{{'userAdmins.email' | translate}}</mat-label>
      <input matInput formControlName="email" type="text">
      <mat-error *ngIf="form.hasError('required', ['email'])">{{'fieldRequired' | translate}}</mat-error>
      <mat-error *ngIf="form.hasError('email', ['email'])">{{'emailInvalid' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{'userAdmins.role' | translate}}</mat-label>
      <mat-select formControlName="role">
        <mat-option value="SUPER_ADMIN" *ngIf="auth.isSuperAdmin">SUPER_ADMIN</mat-option>
        <mat-option value="COMPANY_MANAGER">COMPANY_MANAGER</mat-option>
        <mat-option value="PARTNER" *ngIf="auth.isSuperAdmin || auth.isPartner">PARTNER</mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', ['role'])">{{'fieldRequired' | translate}}</mat-error>
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button type="submit" [disabled]="!form.valid || loading">
        {{loading ? 'saving' : 'save' | translate}}
      </button>
    </div>
  </form>
</div>
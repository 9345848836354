<div class="cancel-container">
  <div class="crossmark-circle">
    <div class="background"></div>
    <div class="crossmark"></div>
  </div>
  <h3>{{ 'trial.expired.cancel.paymentCancelled' | translate }}</h3>
  <span>{{ 'trial.expired.cancel.tryAgainMessage' | translate }}</span>
  <button mat-raised-button color="primary" (click)="retryPayment()"
          [disabled]="isRetryingPayment">
    {{ 'trial.expired.cancel.tryAgain' | translate }}
    <div class="spinner-border spinner-border-sm" role="status" *ngIf="isRetryingPayment"></div>
  </button>
  <button mat-raised-button color="warn" type="button" (click)="backToApp()">
    {{ 'trial.expired.success.close' | translate }}
  </button>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BillingSettingsService } from '../billing/settings/billing-settings.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ChangeBillingSettingsAction, GetBillingSettingsAction } from '../settings/settings.actions';
import { getBillingSettingsSelector } from '../settings/settings.reducer';
import { BillingSettings } from '../settings/settings.interface';
import { Router } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-expired-trial',
  templateUrl: './expired-trial.component.html',
  styleUrls: ['./expired-trial.component.scss'],
})
export class ExpiredTrialComponent implements OnInit {
  @ViewChild('contactPanel') contactPanel: MatExpansionPanel;
  @ViewChild('planPanel') planPanel: MatExpansionPanel;

  isProcessingPayment: boolean = false;

  form: FormGroup = new FormGroup({
    planName: new FormControl({ value: '', disabled: true }),
    price: new FormControl({ value: '', disabled: true }, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]),
    currency: new FormControl({ value: '', disabled: true }),
    planId: new FormControl({ value: '', disabled: true }),
    subscriptionStartDate: new FormControl({ value: '', disabled: true }),
    lastInvoiceUserCount: new FormControl({ value: '', disabled: true }),
    currentUserCount: new FormControl({ value: '', disabled: true }),
    contactName: new FormControl(''),
    contactPhone: new FormControl(''),
    contactEmail: new FormControl(''),
    companyName: new FormControl(''),
    country: new FormControl(''),
    street: new FormControl(''),
    streetNumber: new FormControl(''),
    city: new FormControl(''),
    postalCode: new FormControl(''),
    state: new FormControl(''),
    vatNumber: new FormControl(''),
    vatRate: new FormControl('')
  });

  plans = ['gSignature Basic', 'gSignature Plus', 'gSignature Pro'];
  currencies = ['USD', 'EUR', 'PLN'];

  constructor(
    private billingSettingsService: BillingSettingsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    if (this.auth.isSuperAdmin || this.auth.isCompanyManager || this.auth.isPartner) {
      this.store.dispatch(new GetBillingSettingsAction());
      this.store.select(getBillingSettingsSelector)
        .subscribe((billingSettings: BillingSettings) => {
          if (billingSettings) {
            this.fillForm(billingSettings);
          }
        });
    }
  }

  fillForm(billingSettings: BillingSettings): void {
    this.form.patchValue({
      planName: billingSettings.planName,
      price: billingSettings.price,
      currency: billingSettings.currency,
      planId: billingSettings.planId,
      subscriptionStartDate: new Date(billingSettings.subscriptionStartDate),
      lastInvoiceUserCount: billingSettings.lastInvoiceUserCount,
      currentUserCount: billingSettings.currentUserCount,
      contactName: billingSettings.contactName || '',
      contactPhone: billingSettings.contactPhone || '',
      contactEmail: billingSettings.contactEmail || '',
      companyName: billingSettings.companyName || '',
      country: billingSettings.country || '',
      street: billingSettings.street || '',
      streetNumber: billingSettings.streetNumber || '',
      city: billingSettings.city || '',
      postalCode: billingSettings.postalCode || '',
      state: billingSettings.state || '',
      vatNumber: billingSettings.vatNumber || '',
      vatRate: billingSettings.vatRate
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      if (formValue.subscriptionStartDate instanceof Date) {
        formValue.subscriptionStartDate = formValue.subscriptionStartDate.toISOString();
      }
      this.store.dispatch(new ChangeBillingSettingsAction(formValue));
      this.toastr.success(this.translate.instant('trial.expired.updateSuccess'));
    } else {
      this.form.markAllAsTouched();
    }
  }

  async openPaymentModal(): Promise<void> {
    this.isProcessingPayment = true;
    this.billingSettingsService.validateBillingInfo().subscribe({
      next: (response: { success: boolean; missingFields?: string[] }) => {
        if (response.success) {
          this.billingSettingsService.createCheckoutSession('expired').subscribe({
            next: (stripeResponse: { url: string }) => {
              window.location.href = stripeResponse.url;
            },
            error: () => {
              this.toastr.error(this.translate.instant('trial.expired.paymentError'));
              this.isProcessingPayment = false;
            }
          });
        } else {
          this.toastr.warning(this.translate.instant('trial.expired.fillRequiredFields'));
          this.highlightMissingFields(response.missingFields || []);
          if (this.contactPanel && !this.contactPanel.expanded) {
            this.contactPanel.open();
          }
          setTimeout(() => {
            const formSection = document.getElementById('billing-info-section');
            if (formSection) {
              formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 300);
          this.isProcessingPayment = false;
        }
      },
      error: () => {
        this.toastr.error(this.translate.instant('trial.expired.validationError'));
        this.isProcessingPayment = false;
      }
    });
  }

  private highlightMissingFields(missingFields: string[]): void {
    missingFields.forEach(field => {
      const formControl = this.form.get(field);
      if (formControl) {
        formControl.markAsTouched();
        formControl.setErrors({ required: true });
        const matFormField = document.querySelector(`mat-form-field[formControlName="${field}"]`);
        if (matFormField) {
          matFormField.classList.add('mat-form-field-error');
        }
      }
    });
  }
}

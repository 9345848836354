import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeSetting, PredefinedEmployeeSetting } from '../settings/settings.interface';
import { ChangeEmployeeSettingsAction, GetEmployeeSettingsAction, GetPredefinedEmployeeSettingsAction } from '../settings/settings.actions';
import { getEmployeeSettingsSelector, getPredefinedEmployeeSettingsSelector } from '../settings/settings.reducer';
import { EmployeeSettingsFormDialogComponent } from './employee-settings-form-dialog/employee-settings-form-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { scrollToBottom } from '../shared/utils/window.utils';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-employee-settings',
  templateUrl: './employee-settings.component.html',
  styleUrls: ['./employee-settings.component.scss']
})
export class EmployeeSettingsComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    employeeSettings: new FormArray([]),
  });

  predefinedEmployeeSettingsForm: UntypedFormGroup = new UntypedFormGroup({
    predefinedEmployeeSettings: new UntypedFormControl([]),
  });

  predefinedEmployeeSettings: PredefinedEmployeeSetting[] = [];

  constructor(private store: Store,
              private dialog: MatDialog,
              private translate: TranslateService,
              private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new GetPredefinedEmployeeSettingsAction());
    this.store.dispatch(new GetEmployeeSettingsAction());

    combineLatest([
      this.store.select(getPredefinedEmployeeSettingsSelector),
      this.store.select(getEmployeeSettingsSelector)
    ]).subscribe(([predefined, employeeSettings]) => {
      if (predefined) {
        this.predefinedEmployeeSettings = [...predefined];
      } else {
        this.predefinedEmployeeSettings = [];
      }

      if (employeeSettings) {
        (this.form.get('employeeSettings') as FormArray).clear();
        const selectedSettings: PredefinedEmployeeSetting[] = [];
        employeeSettings.forEach(setting => {
          this.addEmployeeSetting(setting.name, setting.overridable, setting.defaultValue, setting.mandatory, setting.label);
          let existing = this.predefinedEmployeeSettings.find(item => item.name === setting.name);
          if (!existing) {
            existing = {
              id: setting.name,
              name: setting.name,
              overridableByDefault: typeof setting.overridable === 'boolean' ? setting.overridable : true
            };
            this.predefinedEmployeeSettings.push(existing);
          }
          selectedSettings.push(existing);
        });
        this.predefinedEmployeeSettingsForm.get('predefinedEmployeeSettings').setValue(selectedSettings);
      }
    });
  }

  get employeeSettingsList() {
    return this.form.get('employeeSettings') as FormArray;
  }

  compareSettings = (s1: PredefinedEmployeeSetting, s2: PredefinedEmployeeSetting): boolean => {
    return s1 && s2 ? s1.id === s2.id : s1 === s2;
  }

  onChangePredefinedEmployeeSettings = (newSettings: PredefinedEmployeeSetting[]) => {
    const selectedSettings = this.form.get('employeeSettings').value;

    if (newSettings.length > selectedSettings.length) {
      newSettings.forEach((newSetting) => {
        const exists = selectedSettings.find(setting => setting.name === newSetting.name);
        if (!exists) {
          this.addEmployeeSetting(newSetting.name, newSetting.overridableByDefault, '', false, '');
        }
      });
    } else {
      for (let i = selectedSettings.length - 1; i >= 0; i--) {
        const exists = newSettings.find(setting => setting.name === selectedSettings[i].name);
        if (!exists) {
          this.removeEmployeeSetting(i);
        }
      }
    }
    this.onSubmit();
  }

  addEmployeeSetting = (name: string, overridable: boolean, defaultValue: string, mandatory: boolean, label: string) => {
    this.employeeSettingsList.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(name, Validators.required),
        defaultValue: new UntypedFormControl(defaultValue),
        overridable: new UntypedFormControl(overridable),
        mandatory: new UntypedFormControl(mandatory),
        label: new UntypedFormControl(label),
      })
    );
  }

  removeEmployeeSetting(index: number) {
    this.employeeSettingsList.removeAt(index);
  }

  onSubmit = () => {
    if (this.form.valid) {
      this.store.dispatch(new ChangeEmployeeSettingsAction(this.form.value.employeeSettings));
    } else {
      this.form.markAllAsTouched();
    }
  }

  addNewEmployeeSetting = () => {
    this.dialog.open(EmployeeSettingsFormDialogComponent, {})
      .afterClosed()
      .subscribe(data => {
        if (data) {
          const { name, label, defaultValue, overridable, mandatory } = data;
          this.addEmployeeSetting(name, overridable, defaultValue, mandatory, label);
          const newPredefinedSetting: PredefinedEmployeeSetting = { id: name, name, overridableByDefault: true };

          if (!this.predefinedEmployeeSettings.find(item => item.id === name)) {
            this.predefinedEmployeeSettings.push(newPredefinedSetting);
          }
          const currentSelected = this.predefinedEmployeeSettingsForm.get('predefinedEmployeeSettings').value || [];
          if (!currentSelected.find(item => item.id === name)) {
            this.predefinedEmployeeSettingsForm.get('predefinedEmployeeSettings').setValue([...currentSelected, newPredefinedSetting]);
          }

          this.toastr.success(this.translate.instant('settings.addNewSuccess'));
          scrollToBottom();
          this.onSubmit();
        }
      });
  }
}

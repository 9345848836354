import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingSettingsService } from '../settings/billing-settings.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class BillingCancelComponent {
  isRetryingPayment = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private billingSettingsService: BillingSettingsService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.verifySession();
  }

  verifySession(): void {
    const sessionId = this.route.snapshot.queryParamMap.get('session_id');
  
    if (sessionId) {
      this.billingSettingsService.verifyCheckoutSession(sessionId).subscribe({
        next: (response) => {
          if (response.status === 'open') {
            this.translate.get('settings.billing.cancel.paymentCancelledAlert').subscribe((translation: string) => {
              this.toastr.info(translation);
            });
          }
        },
        error: (error) => {
          this.translate.get('settings.billing.cancel.paymentCancelErrorAlert').subscribe((translation: string) => {
            this.toastr.error(translation);
          });
        }
      });
    } else {
      this.translate.get('settings.billing.cancel.missingSessionId').subscribe((translation: string) => {
        this.toastr.warning(translation);
      });
    }
  }

  retryPayment(): void {
    this.isRetryingPayment = true;
    this.billingSettingsService.createCheckoutSession().subscribe({
      next: (response: { url: string }) => {
        window.location.href = response.url;
      },
      error: () => {
        this.toastr.error(this.translate.instant('settings.billing.paymentError'));
        this.isRetryingPayment = false;
      }
    });
  }

  backToBilling(): void {
    this.router.navigate(['/settings/billing']);
  }
}

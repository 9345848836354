import { GoogleSyncState } from './google-sync-state.interface';
import {
  GoogleSyncStateActions,
  ADD_GOOGLE_SYNC_STATE_PROGRESS,
  RESET_GOOGLE_SYNC_STATE,
} from './google-sync-state.actions';
import { createSelector } from '@ngrx/store';

export interface GoogleSyncStateStore {
  currentState?: GoogleSyncState;
}

export const initialState: GoogleSyncStateStore = {};

export function googleSyncStateReducer(
  state: GoogleSyncStateStore = initialState,
  action: GoogleSyncStateActions
): GoogleSyncStateStore {
  switch (action.type) {
    case ADD_GOOGLE_SYNC_STATE_PROGRESS:
      return {
        ...state,
        currentState: action.payload,
      };

    case RESET_GOOGLE_SYNC_STATE:
      return initialState;

    default:
      return state;
  }
}

export const getGoogleSyncStateRoot = (state: any) => state.googleSyncState as GoogleSyncStateStore;
export const getCurrentGoogleSyncState = (state: GoogleSyncStateStore) => state.currentState || null;

export const getCurrentGoogleSyncStateSelector = createSelector(
  getGoogleSyncStateRoot,
  getCurrentGoogleSyncState
);

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IntegrationSettings } from '../settings/settings.interface';

@Injectable({
  providedIn: 'root'
})
export class IntegrationSettingsService {
  constructor(private http: HttpClient) {}

  get(): Observable<IntegrationSettings> {
    return this.http.get<IntegrationSettings>('/settings');
  }

  update(settings: IntegrationSettings): Observable<IntegrationSettings> {
    return this.http.post<IntegrationSettings>('/settings', settings);
  }

  checkSpfRecord(): Observable<{ valid: boolean }> {
    return this.http.get<{ valid: boolean }>(`/settings/spf-check`);
  }
}

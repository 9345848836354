import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent } from "../shared/confirmation-dialog/confirmation-dialog.component";
import { ClientService } from "./client.service";
import { Client } from "./client";
import { ToastrService } from "ngx-toastr";
import { ClientEditDialogComponent } from "./client-edit-dialog/client-edit-dialog.component";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ClientWithStats } from "./client.interface";
import { defaultTrialFinishDate } from "../shared/utils/trial.util";
import { validateFutureDate } from "../shared/validators/date.validator";
import { validateHTTPExists } from "../shared/validators/url.validator";

@Component({
  selector: "app-client",
  templateUrl: "./client.component.html",
  styleUrls: ["./client.component.scss"],
})
export class ClientComponent implements OnInit {
  public removing = false;
  public saving = false;
  public billing = false;
  isSyncingWithPipedrive = false;

  public form = new FormGroup({
    domain: new FormControl("", [Validators.required, validateHTTPExists]),
    googleAdminEmail: new FormControl("", Validators.required),
    isIncludedDemoContent: new FormControl(false),
    trialFinishDate: new FormControl(defaultTrialFinishDate, [
      Validators.required,
      validateFutureDate,
    ]),
  });

  public reloadClients = new ReplaySubject<number>(1);
  public clients$: Observable<ClientWithStats[]>;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private service: ClientService,
    private toastrService: ToastrService
  ) { }

  async ngOnInit() {
    this.clients$ = this.reloadClients.pipe(
      switchMap(() => this.service.getAll())
    );
    this.loadData();
  }

  loadData = () => {
    this.reloadClients.next(1);
  };

  add = () => {
    if (this.form.valid) {
      this.saving = true;
      this.service
        .add(this.form.getRawValue())
        .toPromise()
        .then(() => this.loadData())
        .then(() => {
          this.toastrService.success(
            this.translate.instant("client.addSuccess")
          );
          return (this.saving = false);
        });
    } else {
      this.form.markAllAsTouched();
    }
  };

  remove = (domain: string) => {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant("client.deleteConfirmationText", {
            name: domain,
          }),
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          new Promise((res) => res((this.removing = true)))
            .then(() => this.service.remove(domain).toPromise())
            .then(() => this.loadData())
            .finally(() => (this.removing = false));
        }
      });
  };

  canBeDeleted = (c: Client) => c.planId === "free";

  edit(c: Client) {
    this.dialog
      .open(ClientEditDialogComponent, { data: c })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.loadData();
          this.toastrService.success(
            this.translate.instant("editClient.success")
          );
        }
      });
  }

  syncAllWithPipedrive(): void {
    this.isSyncingWithPipedrive = true;
    this.service.syncAllDealsWithPipedrive().subscribe(() => {
      this.toastrService.success(
        this.translate.instant("settings.billing.syncAllPipedriveSuccess")
      );
      this.isSyncingWithPipedrive = false;
    });
  }
}

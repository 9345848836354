<mat-toolbar class="header">
  <span>
    {{ ('menu.type.' + filters.viewType) | translate }}: {{paginator.length}}
  </span>

  <div class="actions">
    <button mat-raised-button [routerLink]="['request']">{{'employee.sendRequest.label' | translate}}</button>
    <button mat-raised-button (click)="generateReport()">{{ 'client.downloadReport' | translate }}</button>
    <button mat-raised-button (click)="syncGoogle()" [disabled]="(isSyncInProgress$ | async) || syncLoading">
      <div class="spinner-border spinner-border-sm" role="status" *ngIf="(isSyncInProgress$ | async) || syncLoading">
      </div>
      {{ 'employee.sync-google' | translate }}
    </button>

  </div>
</mat-toolbar>

<div class="employees-wrapper">

  <div class="table-search">
    <mat-form-field>
      <input matInput [formControl]="phraseControl" placeholder="{{('employee.search') | translate}}" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'employee.filterBySignature' | translate}}</mat-label>

      <mat-select (selectionChange)="filterBySignature($event.value)" [value]="defaultSignatureFilter">
        <mat-option [value]="undefined">-</mat-option>
        <mat-option *ngFor="let signature of signatures$ | async" [value]="signature.id">{{signature.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'employee.filterByHasSignature.label' | translate}}</mat-label>

      <mat-select (selectionChange)="addFilter('signatureInstalled', $event.value)">
        <mat-option [value]="undefined">{{'employee.filterByHasSignature.ALL' | translate}}</mat-option>
        <mat-option value="ONLY_ENABLED"> {{'employee.filterByHasSignature.ONLY_ENABLED' | translate}}</mat-option>
        <mat-option value="ONLY_DISABLED"> {{'employee.filterByHasSignature.ONLY_DISABLED' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field>
      <mat-label>{{'employee.filterByExcluded.label' | translate}}</mat-label>

      <mat-select (selectionChange)="addFilter('excludedFromSignatures', $event.value)">
        <mat-option [value]="undefined">{{'employee.filterByExcluded.ALL' | translate}}</mat-option>
        <mat-option value="ONLY_ENABLED"> {{'employee.filterByExcluded.ONLY_ENABLED' | translate}}</mat-option>
        <mat-option value="ONLY_DISABLED"> {{'employee.filterByExcluded.ONLY_DISABLED' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'employee.filterByGroup.label' | translate }}</mat-label>
      <mat-select (selectionChange)="addFilter('groupEmail', $event.value)" (opened)="loadGroupEmails()">

        <mat-option [value]="undefined" *ngIf="groupEmails.loading" class="groups-loader">
          <mat-spinner diameter="24" class="mx-auto"></mat-spinner>
        </mat-option>

        <ng-container *ngIf="!groupEmails.loading">
          <mat-option [value]="undefined">{{ 'employee.filterByGroup.ALL' | translate }}</mat-option>
          <mat-option *ngFor="let groupEmail of groupEmails.data" [value]="groupEmail">{{ groupEmail }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'employee.viewSelection.label' | translate }}</mat-label>
      <mat-select [(value)]="filters.viewType" (selectionChange)="toggleView()">
        <mat-option value="EMPLOYEES">{{ 'employee.viewSelection.viewEmployees' | translate }}</mat-option>
        <mat-option value="ALIASES">{{ 'employee.viewSelection.viewAliases' | translate }}</mat-option>
        <mat-option value="BOTH">{{ 'employee.viewSelection.viewBoth' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <mat-table (matSortChange)="sortChange($event)" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="fullName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.fullName' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="company-wrapper">
        <div *ngIf="element.fullName">{{ element.fullName }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'employee.email' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.email">{{ element.email }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>{{ 'employee.type' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          <span *ngIf="element.localData; else nonLocal">
            {{ 'employee.local' | translate }}
          </span>
          <ng-template #nonLocal>
            {{ element.isAlias ? ('employee.alias' | translate) : ('employee.employee' | translate) }}
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="emptyFieldsCount">
      <mat-header-cell *matHeaderCellDef>{{'employee.emptyFieldsCount' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.emptyFieldsCount">{{ element.emptyFieldsCount }}</div>
      </mat-cell>
    </ng-container> -->

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'employee.createdAt' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.createdAt" class="date-time-container">
          <div>{{ element.createdAt | localizedDate:'mediumDate' }},</div>
          <div>{{ element.createdAt | localizedDate:'mediumTime' }}</div>
        </div>
      </mat-cell>
    </ng-container>
    
    
    <ng-container matColumnDef="signatureInstallationAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'employee.signatureInstallationAt' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.signatureInstallationAt" class="date-time-container">
          <div>{{ element.signatureInstallationAt | localizedDate:'mediumDate' }},</div>
          <div>{{ element.signatureInstallationAt | localizedDate:'mediumTime' }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="modifiedAt">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'employee.modifiedAt' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="element.modifiedAt" class="date-time-container">
          <div>{{ element.modifiedAt | localizedDate:'mediumDate' }},</div>
          <div>{{ element.modifiedAt | localizedDate:'mediumTime' }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signatureTemplateId">
      <mat-header-cell *matHeaderCellDef>
        {{ 'employee.signatureTemplateIdColumn' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="signature-cell">
        <ng-container *ngIf="element.signatureTemplateId || element.signatureInternalTemplateId; else noSignature">
          <div class="signature-grid">
            <ng-container *ngIf="element.signatureTemplateId">
              <div class="signature-row">
                <div class="signature-label-column" matTooltip="{{ 'externalSignature' | translate }}">
                  <mat-icon aria-label="external" class="icon-muted">launch</mat-icon>
                </div>
                <div class="signature-name-column">
                  <a class="chip ellipsis"
                     [title]="(signatureNamePerId$ | async)?.get(element.signatureTemplateId)"
                     [routerLink]="['../signatures', element.signatureTemplateId]">
                    {{ (signatureNamePerId$ | async)?.get(element.signatureTemplateId) }}
                  </a>
                </div>
                <div class="signature-close-column">
                  <button mat-icon-button class="remove-signature-button"
                          aria-label="Remove signature template"
                          (click)="removeSignatureTemplate(element.id, element.isAlias)"
                          matTooltip="{{ 'employee.removeSignatureTemplate' | translate }}">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="element.signatureInternalTemplateId">
              <div class="signature-row">
                <div class="signature-label-column" matTooltip="{{ 'internalSignature' | translate }}">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" class="icon-muted">
                    <title>{{ 'signatures.internalInstallationsTooltip' | translate }}</title>
                    <path d="M19 19H5V5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7z"/>
                    <path d="M16 15v-2h-3.59L20 5.41l-1.41-1.41L11 11.59V8h-2v7h7z" />
                  </svg>
                </div>   
                <div class="signature-name-column">
                  <a class="chip ellipsis"
                     [title]="(signatureInternalNamePerId$ | async)?.get(element.signatureInternalTemplateId)"
                     [routerLink]="['../signatures', element.signatureInternalTemplateId]">
                    {{ (signatureInternalNamePerId$ | async)?.get(element.signatureInternalTemplateId) }}
                  </a>
                </div>
                <div class="signature-close-column">
                  <button mat-icon-button class="remove-signature-button"
                          aria-label="Remove internal signature template"
                          (click)="removeInternalSignatureTemplate(element.id, element.isAlias)"
                          matTooltip="{{ 'employee.removeInternalSignatureTemplate' | translate }}">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noSignature>
          <span>-</span>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signatureAccepted">
      <mat-header-cell *matHeaderCellDef>{{'employee.signatureAccepted' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox *ngIf="!element.isAlias" [disabled]="true" [checked]="element.signatureAccepted"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="excludedFromSignatures">
      <mat-header-cell *matHeaderCellDef>
        <div class="excluded">
          {{ 'employee.excludedFromSignatures' | translate }}
          <mat-checkbox *ngIf="canExcludeAnyEmployee" (change)="toggleExcludeAll($event.checked)"
            [checked]="isAllExcluded" [indeterminate]="isSomeExcluded" [disabled]="!canExcludeAnyEmployee"
            [matTooltip]="'employee.excludeAllSignatures' | translate">
          </mat-checkbox>
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox *ngIf="!element.isAlias" [disabled]="!element.canBeExcludedFromSignatures"
          (change)="$event ? changeExcludedFromSignatures($event.checked, element) : null"
          [checked]="element.excludedFromSignatures"
          [matTooltip]="element.canBeExcludedFromSignatures ? undefined : 'employee.cannotBeExcludedFromSignatures' | translate"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef sticky></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a *ngIf="!element.isAlias" [routerLink]="[element.id, 'edit']">
          <mat-icon>edit</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'alias-row': row.isAlias, 'tall-row': row.signatureTemplateId && row.signatureInternalTemplateId}">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="paginator.length" [pageSize]="paginator.pageSize"
    (page)="changePage($event)"></mat-paginator>

  <div class="sync">
    <app-employee-local-import *ngIf="!appUser?.lastGoogleSync" />

    <app-employee-data-import *ngIf="appUser?.lastGoogleSync" />

    <button mat-raised-button (click)="downloadAllSignatures()">
      {{ 'employee.downloadAllSignatures' | translate }}
    </button>
  </div>

</div>
